import React, { useState, useEffect } from "react";
import { BsFacebook } from "react-icons/bs";
import { SiShopify, SiEbay, SiEtsy } from "react-icons/si";
import { LuUploadCloud } from "react-icons/lu";
import { BiPlus } from "react-icons/bi";
import {
  ConnectionModal,
  ShopifyConnectionModal,
} from "../components/Dashboard";
import { PlatformConStatus } from "../components/Dashboard";
import { InventoryTable } from "../components/Inventory";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import InventoryIcon from "@mui/icons-material/Inventory";
import DraftsIcon from "@mui/icons-material/Drafts";
import ImportModal from "../components/ImportModal";
import platformList from "../Utils/platformList";

export const Inventory = (props) => {
  const {
    connectedPlatforms,
    inventoryData,
    connectionStatus,
    setConnectionStatus,
    connectingStatus,
    setConnectingStatus,
  } = props;
  const [modalStatus, setModalStatus] = useState({
    status: false,
    platform: null,
  });
  const [showImportModal, setShowImportModal] = useState(false);
  // console.log(modalStatus);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  console.log(modalStatus);

  useEffect(() => {
    if (connectedPlatforms) {
      connectedPlatforms.forEach((item) => {
        setConnectionStatus((prev) => ({
          ...prev,
          [item.platform]: item.status,
        }));
      });
    }
  }, [connectedPlatforms]);

  useEffect(() => {
    dispatch({ type: "RESET_STAGING_DETAILS" });
  }, []);

  //animation
  useEffect(() => {
    const paragraphs = document.querySelectorAll(".fade-in");
    paragraphs.forEach((p) => {
      p.classList.add("animate-fade-in");
    });
  }, []);

  return (
    <div className="fade-in py-4 w-full overflow-y-auto min-h-screen relative">
      <div className="flex-1 px-7  w-full flex items-center justify-center sm:items-stretch sm:justify-between pb-[5.9px]">
        <div className="flex justify-between sm:block gap-x-1 sm:gap-x-0 items-center">
          <p className="title fade-in">Dashboard</p>
        </div>
        <div className="flex item-center gap-x-5">
          <div className="group relative">
            <button
              onClick={() => {
                console.log("hi");
                setShowImportModal(true);
              }}
              className="btn-secondary flex items-center gap-x-1.5 text-Inter cursor-pointer bg-gray-100 border-gray-700 text-gray-700"
            >
              <LuUploadCloud className="h-4 w-4 mt-1" />
              Import
            </button>
          </div>
          <button
            onClick={() => {
              dispatch({ type: "RESET_STAGING_DETAILS" });
              navigate("staging");
            }}
            className="btn-primary text-Inter flex item-center gap-x-1.5 text-Inter"
          >
            <BiPlus className="h-4 w-4 mt-1 text-white" />
            Add product
          </button>
        </div>
      </div>
      <hr className="bg-gray-200 h-[1.15px] mt-3" />

      <div className="mt-8">
        <div className="flex items-center w-full gap-x-4 px-8">
          <div className="w-1/4 py-5 px-4 flex items-center justify-between rounded-xl border-[1px] border-slate-300">
            <div
              style={{
                border: "7px solid #ecfdf3",
                backgroundColor: "#d1fadf",
                padding: "7px",
                borderRadius: "50%",
              }}
            >
              <InventoryIcon
                style={{
                  color: "#039855",
                  fontSize: "25px",
                }}
              ></InventoryIcon>
            </div>
            {/* <svg
                            width="52"
                            height="52"
                            viewBox="0 0 56 56"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="4"
                                y="4"
                                width="48"
                                height="48"
                                rx="24"
                                fill="#D1FADF"
                            />
                            <path
                                d="M32 34L34 36L38 32M28 31H24C22.1362 31 21.2044 31 20.4693 31.3045C19.4892 31.7105 18.7105 32.4892 18.3045 33.4693C18 34.2044 18 35.1362 18 37M31.5 19.2908C32.9659 19.8841 34 21.3213 34 23C34 24.6787 32.9659 26.1159 31.5 26.7092M29.5 23C29.5 25.2091 27.7091 27 25.5 27C23.2909 27 21.5 25.2091 21.5 23C21.5 20.7909 23.2909 19 25.5 19C27.7091 19 29.5 20.7909 29.5 23Z"
                                stroke="#039855"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <rect
                                x="4"
                                y="4"
                                width="48"
                                height="48"
                                rx="24"
                                stroke="#ECFDF3"
                                stroke-width="8"
                            />
                        </svg> */}
            <div>
              <p className="text-Inter text-sm font-[500] tracking-wide text-gray-500">
                Total Product
              </p>
              <p className="text-Inter text-3xl text-right font-bold tracking-wide text-gray-600">
                {inventoryData.length}
              </p>
            </div>
          </div>
          <div className="w-1/4 py-5 px-4 flex items-center justify-between rounded-xl border-[1px] border-slate-300">
            <svg
              width="52"
              height="52"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
              <path
                d="M28 31.5H23.5C22.1044 31.5 21.4067 31.5 20.8389 31.6722C19.5605 32.06 18.56 33.0605 18.1722 34.3389C18 34.9067 18 35.6044 18 37M32 34L34 36L38 32M30.5 23.5C30.5 25.9853 28.4853 28 26 28C23.5147 28 21.5 25.9853 21.5 23.5C21.5 21.0147 23.5147 19 26 19C28.4853 19 30.5 21.0147 30.5 23.5Z"
                stroke="#039855"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <rect
                x="4"
                y="4"
                width="48"
                height="48"
                rx="24"
                stroke="#ECFDF3"
                stroke-width="8"
              />
            </svg>

            <div>
              <p className="text-Inter text-sm font-[500] tracking-wide text-gray-500">
                Total Listings
              </p>
              <p className="text-Inter text-3xl text-right font-bold tracking-wide text-gray-600">
                {inventoryData.reduce((acc, inventory) => {
                  return (
                    acc +
                    inventory.listings?.filter(
                      (listing) => listing.status === "listed"
                    ).length
                  );
                }, 0)}
              </p>
            </div>
          </div>
          <div className="w-1/4 py-5 px-4 flex items-center justify-between rounded-xl border-[1px] border-slate-300">
            <svg
              width="52"
              height="52"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
              <path
                d="M31 33V37H25V33M21.2 33H34.8C35.9201 33 36.4802 33 36.908 32.782C37.2843 32.5903 37.5903 32.2843 37.782 31.908C38 31.4802 38 30.9201 38 29.8V22.2C38 21.0799 38 20.5198 37.782 20.092C37.5903 19.7157 37.2843 19.4097 36.908 19.218C36.4802 19 35.9201 19 34.8 19H21.2C20.0799 19 19.5198 19 19.092 19.218C18.7157 19.4097 18.4097 19.7157 18.218 20.092C18 20.5198 18 21.0799 18 22.2V29.8C18 30.9201 18 31.4802 18.218 31.908C18.4097 32.2843 18.7157 32.5903 19.092 32.782C19.5198 33 20.0799 33 21.2 33Z"
                stroke="#039855"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <rect
                x="4"
                y="4"
                width="48"
                height="48"
                rx="24"
                stroke="#ECFDF3"
                stroke-width="8"
              />
            </svg>

            <div>
              <p className="text-Inter text-sm font-[500] tracking-wide text-gray-500">
                Active now
              </p>
              <p className="text-Inter text-3xl text-right font-bold tracking-wide text-gray-600">
                {inventoryData.reduce((acc, inventory) => {
                  return (
                    acc +
                    +(
                      inventory.listings?.filter(
                        (listing) => listing.status === "listed"
                      ).length > 0
                    )
                  );
                }, 0)}
              </p>
            </div>
          </div>
          <div className="w-1/4 py-5 px-4 flex items-center justify-between rounded-xl border-[1px] border-slate-300">
            <div
              style={{
                border: "7px solid #ecfdf3",
                backgroundColor: "#d1fadf",
                padding: "7px",
                borderRadius: "50%",
              }}
            >
              <DraftsIcon
                style={{
                  color: "#039855",
                  fontSize: "25px",
                }}
              ></DraftsIcon>
            </div>
            {/* <svg
                            width="52"
                            height="52"
                            viewBox="0 0 56 56"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="4"
                                y="4"
                                width="48"
                                height="48"
                                rx="24"
                                fill="#D1FADF"
                            />
                            <path
                                d="M32 34L34 36L38 32M28 31H24C22.1362 31 21.2044 31 20.4693 31.3045C19.4892 31.7105 18.7105 32.4892 18.3045 33.4693C18 34.2044 18 35.1362 18 37M31.5 19.2908C32.9659 19.8841 34 21.3213 34 23C34 24.6787 32.9659 26.1159 31.5 26.7092M29.5 23C29.5 25.2091 27.7091 27 25.5 27C23.2909 27 21.5 25.2091 21.5 23C21.5 20.7909 23.2909 19 25.5 19C27.7091 19 29.5 20.7909 29.5 23Z"
                                stroke="#039855"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <rect
                                x="4"
                                y="4"
                                width="48"
                                height="48"
                                rx="24"
                                stroke="#ECFDF3"
                                stroke-width="8"
                            />
                        </svg> */}
            <div>
              <p className="text-Inter text-sm font-[500] tracking-wide text-gray-500">
                Drafting
              </p>
              <p className="text-Inter text-3xl text-right font-bold tracking-wide text-gray-600">
                {inventoryData.reduce((acc, inventory) => {
                  return (
                    acc +
                    +(
                      inventory.listings?.filter(
                        (listing) => listing.status === "listed"
                      ).length === 0
                    )
                  );
                }, 0)}
              </p>
            </div>
          </div>
        </div>

        <div className="px-7 mt-5 w-full">
          <div className="">
            <p className="text-xl text-gray-800 font-semibold text-Inter">
              Platforms
            </p>
          </div>

          <div className="mt-3 flex item-center gap-x-4">
            {platformList.map((item, index) => (
              <PlatformConStatus
                item={item}
                key={index}
                connectionStatus={connectionStatus}
                setConnectionStatus={setConnectionStatus}
                setModalStatus={setModalStatus}
                connectingStatus={connectingStatus}
                setConnectingStatus={setConnectingStatus}
              />
            ))}
          </div>
        </div>

        <div className="mt-4">
          <div>
            <InventoryTable />
          </div>
        </div>
      </div>

      {modalStatus.platform !== "shopify" &&
        modalStatus.platform !== "alliwant" &&
        modalStatus.status && (
          <ConnectionModal
            modalStatus={modalStatus}
            setModalStatus={setModalStatus}
            connectingStatus={connectingStatus}
            setConnectingStatus={setConnectingStatus}
          />
        )}
      {(modalStatus.platform === "shopify" ||
        modalStatus.platform === "alliwant") &&
        modalStatus.status && (
          <ShopifyConnectionModal
            modalStatus={modalStatus}
            setModalStatus={setModalStatus}
            connectingStatus={connectingStatus}
            setConnectingStatus={setConnectingStatus}
          />
        )}

      {showImportModal && (
        <ImportModal
          cancelImport={() => {
            setShowImportModal(false);
          }}
        ></ImportModal>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  connectedPlatforms: state.auth.connectedPlatforms,
  inventoryData: state.inventory.inventoryData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
