import React, { useEffect } from "react";
import { LuUploadCloud } from "react-icons/lu";
import { BiPlus } from "react-icons/bi";
import { Schedule } from "../components/Tasks";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { getAllTask } from "../redux/actions/task";

export const Tasks = (props) => {
    const { getAllTask } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        getAllTask();
        dispatch({ type: "RESET_STAGING_DETAILS" });
    }, []);

    //animation
    useEffect(() => {
        const paragraphs = document.querySelectorAll(".fade-in");
        paragraphs.forEach((p) => {
            p.classList.add("animate-fade-in");
        });
    }, []);

    return (
        <div className="py-4 fade-in w-full overflow-y-auto min-h-screen relative">
            <div className="flex-1 px-7  w-full flex items-center justify-center sm:items-stretch sm:justify-between pb-[5.9px]">
                <div className="flex justify-between sm:block gap-x-1 sm:gap-x-0 items-center">
                    <p className="title fade-in">Tasks</p>
                </div>
                {/* <div className="flex item-center gap-x-5">
                    <button className="btn-secondary flex item-center gap-x-1.5 text-Inter">
                        <LuUploadCloud className='h-4 w-4 mt-1' />
                        Import
                    </button>
                    <button
                        onClick={() => {
                            navigate('staging')
                        }}
                        className="btn-primary text-Inter flex item-center gap-x-1.5 text-Inter">
                        <BiPlus className='h-4 w-4 mt-1 text-white' />
                        Add product
                    </button>
                </div> */}
            </div>
            <hr className="bg-gray-200 h-[1.5px] mt-[13px]" />

            <div className="mt-8">
                <Schedule />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { getAllTask };

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
