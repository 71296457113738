const initState = {
    inventoryData: [],
}

export default (state = initState, action) => {
    switch (action.type) {
        case 'SET_INVENTORY_DATA':
            return {
                ...state,
                inventoryData: action.payload
            }
        case 'UPDATE_LISTING':
            const { listingId, inventoryId, data } = action.payload;
            var tempInventoryData = [...state.inventoryData];
            var updatedInventoryData = tempInventoryData.map((inventory) => {
                if (inventory.id === parseInt(inventoryId)) {
                    var tempListing = [...inventory.listings];
                    var updatedListing = tempListing.map((listing) => {
                        if (listing.id === parseInt(listingId)) {
                            return {
                                ...listing,
                                data: { ...data }
                            }
                        } else {
                            return listing
                        }
                    })
                    return {
                        ...inventory,
                        listings: updatedListing
                    }
                } else {
                    return inventory
                }
            })

            console.log(updatedInventoryData, 'updatedInventoryData')
            return {
                ...state,
                inventoryData: updatedInventoryData
            }
        default:
            return state;

    }
}
