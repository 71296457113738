import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { BiPlus } from "react-icons/bi";
import {
  CreateWants,
  WantsTable,
  CreateAllIWant,
  AlliWantTable,
} from "../components/wants";
import authenticatedInstance from "../redux/actions/AxiosInstance/authenticated";
import { getAllWants } from "../redux/actions/wants";
import { toast } from "sonner";
import AllInboxIcon from "@mui/icons-material/AllInbox";

export const Wants = (props) => {
  const { getAllWants } = props;
  const [loading, setLoading] = useState(false);
  const [createModalStatus, setCreateModalStatus] = React.useState(false);
  const [modalType, setModalType] = useState("create");
  const [updatingWant, setUpdatingWant] = useState(null);
  const [curTab, steCurTab] = useState("all");
  const [alliwantModalStatus, setAlliwantModalStatus] = React.useState(false);
  const [alliwantModalType, setAlliwantModalType] = React.useState("create");
  const [viewingWant, setViewingWant] = useState(null);

  const [alliwantWants, setAlliwantWants] = useState([]);
  const [alliwantLoading, setAlliwantLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setAlliwantLoading(true);
        const response = await authenticatedInstance.get("/wants/getAlliwant");
        console.log(response.data);
        setAlliwantWants(response.data);
        setAlliwantLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);

  const updateWantHandler = (data) => {
    console.log(data);
    setModalType("update");
    setUpdatingWant({ ...data });
    setCreateModalStatus(true);
  };

  const updateWantComplete = () => {
    setCreateModalStatus(false);
    setUpdatingWant(null);
    setModalType("create");
  };

  const createWantHandler = () => {
    setModalType("create");
    setCreateModalStatus(true);
    setUpdatingWant(null);
  };

  const createWantComplete = () => {
    setCreateModalStatus(false);
    setUpdatingWant(null);
    setModalType("create");
  };

  const viewWantHandler = (data) => {
    setViewingWant({ ...data });
    setAlliwantModalType("view");
    setAlliwantModalStatus(true);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        await getAllWants();
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  const newWantAddedHandler = (data) => {
    setAlliwantWants((prev) => [...prev, data]);
  };

  const deleteAlliwantHandler = async (id) => {
    try {
      const response = await authenticatedInstance.post(
        "/wants/alliwantDelete",
        {
          wantsId: id,
        }
      );
      setAlliwantWants((prev) => prev.filter((want) => want.id !== id));
      console.log(response.data);
      // toast.success("want deleted successfully");
      console.log(response.data);
    } catch (e) {
      console.log(e);
      toast.error("something went wrong while deleting the want");
    }
  };

  //animation
  useEffect(() => {
    const paragraphs = document.querySelectorAll(".fade-in");
    paragraphs.forEach((p) => {
      p.classList.add("animate-fade-in");
    });
  }, []);

  let content = (
    <>
      <div className="mt-4">
        <WantsTable
          updateWantComplete={updateWantComplete}
          updateWantHandler={updateWantHandler}
        />
      </div>

      {createModalStatus && (
        <CreateWants
          setCreateModalStatus={setCreateModalStatus}
          modalType={modalType}
          updatingWant={updatingWant}
          createWantComplete={createWantComplete}
          updateWantComplete={updateWantComplete}
        />
      )}
    </>
  );

  if (curTab === "alliwant") {
    content = (
      <>
        <div className="mt-4">
          <AlliWantTable
            viewWantHandler={viewWantHandler}
            data={alliwantWants}
            loading={alliwantLoading}
            deleteAlliwantHandler={deleteAlliwantHandler}
          ></AlliWantTable>
        </div>
        {alliwantModalStatus && (
          <CreateAllIWant
            modalType={alliwantModalType}
            viewingWant={viewingWant}
            cancelHandler={() => {
              setAlliwantModalStatus(false);
            }}
            newWantAddedHandler={newWantAddedHandler}
          />
        )}
      </>
    );
  }

  let activeTabClass =
    "inline-flex items-center px-4 py-3 text-white bg-primary rounded-lg active w-full";
  let normalTabClass =
    "inline-flex items-center px-4 py-3 rounded-lg hover:text-gray-900 bg-gray-50 hover:bg-gray-100 w-full dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white";

  return (
    <div className="py-4 fade-in w-full overflow-y-auto min-h-screen relative">
      <div className="flex-1 px-7  w-full flex items-center justify-center sm:items-stretch sm:justify-between pb-[5.9px]">
        <div className="flex justify-between sm:block gap-x-1 sm:gap-x-0 items-center">
          <p className="title fade-in">Wants</p>
        </div>
        <div className="flex item-center gap-x-5">
          <button
            onClick={() => {
              if (curTab === "alliwant") {
                setAlliwantModalStatus(true);
                setAlliwantModalType("create");
                setViewingWant(null);
                return;
              }
              createWantHandler();
            }}
            className="btn-primary text-Inter flex item-center gap-x-1.5 text-Inter"
          >
            <BiPlus className="h-4 w-4 mt-1 text-white" />
            Add Wants
          </button>
        </div>
      </div>
      <hr className="bg-gray-200 h-[1.15px] mt-3" />

      <div
        class="text-sm font-medium text-center text-gray-500"
        style={{
          paddingLeft: "80px",
          marginTop: "15px",
        }}
      >
        <ul class="flex flex-wrap text-sm font-medium text-center">
          <li className="m-2">
            <button
              // href="#"
              class={curTab === "all" ? activeTabClass : normalTabClass}
              onClick={() => {
                steCurTab("all");
              }}
            >
              {/* <svg
                class="w-4 h-4 me-2 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
              </svg> */}
              <AllInboxIcon
                style={{
                  width: "1.2rem",
                  height: "1.2rem",
                  marginInlineEnd: "0.5rem",
                }}
              ></AllInboxIcon>
              All
            </button>
          </li>
          <li className="m-2">
            <button
              // href="#"
              class={curTab === "alliwant" ? activeTabClass : normalTabClass}
              onClick={() => {
                steCurTab("alliwant");
              }}
            >
              <img
                style={{
                  height: "1.2rem",
                  marginInlineEnd: "0.5rem",
                }}
                src={require("../Assets/alliwantlogo.png")}
              ></img>
              All I Want
            </button>
          </li>
        </ul>
      </div>

      {/* <div>
        <button
          onClick={() => {
            steCurTab("all");
            setAlliwantModalStatus(false);
          }}
        >
          All
        </button>
        <button
          onClick={() => {
            steCurTab("alliwant");
          }}
        >
          All I Want
        </button>
      </div> */}

      {content}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { getAllWants };

export default connect(mapStateToProps, mapDispatchToProps)(Wants);
