export const categories = [
  { label: "Kid Clothing", value: "Kid Clothing" },
  { label: "Kid Shoes", value: "Kid Shoes" },
  { label: "Kid Accessories", value: "Kid Accessories" },
  { label: "Children's Books & Toys", value: "Children's Books & Toys" },
  { label: "Baby Essentials", value: "Baby Essentials" },
  { label: "Diaper Bags & Carries", value: "Diaper Bags & Carries" },
  { label: "Children's Room & Nursery", value: "Children's Room & Nursery" },
  { label: "Maternity", value: "Maternity" },
  { label: "Mama Clothing", value: "Mama Clothing" },
  { label: "Mama Shoes", value: "Mama Shoes" },
  { label: "Mama Accessories", value: "Mama Accessories" },
];

export const conditions = [
  { label: "New with tag", value: "New with tag" },
  { label: "New without tag", value: "New without tag" },
  { label: "Excellent used condition", value: "Excellent used condition" },
  { label: "Very good used condition", value: "Very good used condition" },
  { label: "Good used condition", value: "Good used condition" },
  { label: "Play condition", value: "Play condition" },
];

export const subCategories = {
  "Kid Clothing": [
    { label: "Tops & Tees", value: "Tops & Tees" },
    { label: "Sweaters & Sweatshirts", value: "Sweaters & Sweatshirts" },
    { label: "Jeans", value: "Jeans" },
    { label: "Pants", value: "Pants" },
    { label: "Leggings", value: "Leggings" },
    { label: "Shorts", value: "Shorts" },
    { label: "Dresses", value: "Dresses" },
    { label: "Formal Dresses", value: "Formal Dresses" },
    { label: "Skirts", value: "Skirts" },
    { label: "Outfits & Bundles", value: "Outfits & Bundles" },
    { label: "Rompers & Overalls", value: "Rompers & Overalls" },
    { label: "Onesies", value: "Onesies" },
    { label: "Outerwear", value: "Outerwear" },
    { label: "Activewear", value: "Activewear" },
    { label: "Dancewear & Gymnastics", value: "Dancewear & Gymnastics" },
    { label: "Swim", value: "Swim" },
    { label: "Sleepwear", value: "Sleepwear" },
    { label: "Suits", value: "Suits" },
    { label: "Undergarments", value: "Undergarments" },
    { label: "Costumes", value: "Costumes" },
    { label: "Other", value: "Other" },
  ],
  "Kid Shoes": [
    { label: "Sneakers", value: "Sneakers" },
    { label: "Boots", value: "Boots" },
    { label: "Flats", value: "Flats" },
    { label: "Sandals", value: "Sandals" },
    { label: "Slippers", value: "Slippers" },
    { label: "Loafers", value: "Loafers" },
    { label: "Clogs & Mules", value: "Clogs & Mules" },
    { label: "Dress Shoes", value: "Dress Shoes" },
    { label: "Moccasins", value: "Moccasins" },
    { label: "Booties & Soft Soles", value: "Booties & Soft Soles" },
    { label: "Athletic", value: "Athletic" },
    { label: "Dance", value: "Dance" },
    { label: "Cleats", value: "Cleats" },
    { label: "Skates", value: "Skates" },
    { label: "Other", value: "Other" },
  ],
  "Kid Accessories": [
    { label: "Hats & Scarves", value: "Hats & Scarves" },
    { label: "Legwear", value: "Legwear" },
    { label: "Gloves & Mittens", value: "Gloves & Mittens" },
    { label: "Hair Accessories", value: "Hair Accessories" },
    { label: "Bags & Backpacks", value: "Bags & Backpacks" },
    { label: "Socks", value: "Socks" },
    { label: "Jewelry", value: "Jewelry" },
    { label: "Belts & Suspenders", value: "Belts & Suspenders" },
    { label: "Ties", value: "Ties" },
    { label: "Sunglasses", value: "Sunglasses" },
    { label: "Other", value: "Other" },
  ],
  "Children's Books & Toys": [
    { label: "Parenting Books", value: "Parenting Books" },
    { label: "Children's Books", value: "Children's Books" },
    { label: "Games & Puzzles", value: "Games & Puzzles" },
    { label: "Infant Toys", value: "Infant Toys" },
    { label: "Toddler Toys", value: "Toddler Toys" },
    { label: "Kid Toys", value: "Kid Toys" },
    { label: "Educational Material", value: "Educational Material" },
    { label: "Music & Videos", value: "Music & Videos" },
    { label: "Other", value: "Other" },
  ],
  "Baby Essentials": [
    { label: "Sleeping", value: "Sleeping" },
    { label: "Feeding", value: "Feeding" },
    { label: "Nursing", value: "Nursing" },
    { label: "Soothing", value: "Soothing" },
    { label: "Traveling", value: "Traveling" },
    { label: "Bathing", value: "Bathing" },
    { label: "Diapering", value: "Diapering" },
    { label: "Other", value: "Other" },
  ],
  "Diaper Bags & Carries": [
    { label: "Bags", value: "Bags" },
    { label: "Carriers & Slings", value: "Carriers & Slings" },
    { label: "Other", value: "Other" },
  ],
  "Children's Room & Nursery": [
    { label: "Bedding", value: "Bedding" },
    { label: "Decor", value: "Decor" },
    { label: "Other", value: "Other" },
  ],
  Maternity: [
    { label: "Tops & Tees", value: "Tops & Tees" },
    { label: "Sweaters & Sweatshirts", value: "Sweaters & Sweatshirts" },
    { label: "Jeans", value: "Jeans" },
    { label: "Pants", value: "Pants" },
    { label: "Leggings", value: "Leggings" },
    { label: "Shorts", value: "Shorts" },
    { label: "Dresses", value: "Dresses" },
    { label: "Skirts", value: "Skirts" },
    { label: "Outfits & Bundles", value: "Outfits & Bundles" },
    { label: "Outerwear", value: "Outerwear" },
    { label: "Activewear", value: "Activewear" },
    { label: "Swim", value: "Swim" },
    { label: "Sleepwear", value: "Sleepwear" },
    {
      label: "Undergarments & Shapewear",
      value: "Undergarments & Shapewear",
    },
    { label: "Other", value: "Other" },
  ],
  "Mama Clothing": [
    { label: "Tops & Tees", value: "Tops & Tees" },
    { label: "Sweaters & Sweatshirts", value: "Sweaters & Sweatshirts" },
    { label: "Jeans", value: "Jeans" },
    { label: "Pants", value: "Pants" },
    { label: "Shorts", value: "Shorts" },
    { label: "Dresses", value: "Dresses" },
    { label: "Skirts", value: "Skirts" },
    { label: "Jumpsuits & Rompers", value: "Jumpsuits & Rompers" },
    { label: "Outerwear", value: "Outerwear" },
    { label: "Activewear", value: "Activewear" },
    { label: "Swim", value: "Swim" },
    { label: "Sleepwear & Intimates", value: "Sleepwear & Intimates" },
    { label: "Loungewear", value: "Loungewear" },
    { label: "Shapewear", value: "Shapewear" },
  ],
  "Mama Shoes": [
    { label: "Sneakers", value: "Sneakers" },
    { label: "Boots", value: "Boots" },
    { label: "Flats", value: "Flats" },
    { label: "Sandals", value: "Sandals" },
    { label: "Platforms", value: "Platforms" },
    { label: "Heels & Wedges", value: "Heels & Wedges" },
    { label: "Slippers", value: "Slippers" },
    { label: "Loafers", value: "Loafers" },
    { label: "Clogs & Mules", value: "Clogs & Mules" },
    { label: "Moccasins", value: "Moccasins" },
    { label: "Athletic", value: "Athletic" },
  ],
  "Mama Accessories": [
    { label: "Bags", value: "Bags" },
    { label: "Jewelry", value: "Jewelry" },
    { label: "Scarves", value: "Scarves" },
    { label: "Hats", value: "Hats" },
    { label: "Socks & Hosiery", value: "Socks & Hosiery" },
    { label: "Sunglasses", value: "Sunglasses" },
    { label: "Belts", value: "Belts" },
    { label: "Hair Accessories", value: "Hair Accessories" },
  ],
};

const kidClothing = [
  {
    label: "Baby",
    options: [
      { value: "preemie", label: "preemie" },
      { value: "nb", label: "nb" },
      { value: "0-3 mo", label: "0-3 mo" },
      { value: "3-6 mo", label: "3-6 mo" },
      { value: "6-12 mo", label: "6-12 mo" },
      { value: "12-18 mo", label: "12-18 mo" },
      { value: "18-24 mo", label: "18-24 mo" },
      { value: "One Size", label: "One Size" },
    ],
  },
  {
    label: "Toddler",
    options: [
      { value: "2", label: "2" },
      { value: "2T", label: "2T" },
      { value: "3", label: "3" },
      { value: "3T", label: "3T" },
      { value: "4", label: "4" },
      { value: "4T", label: "4T" },
    ],
  },
  {
    label: "Little Kid",
    options: [
      { value: "5", label: "5" },
      { value: "5T", label: "5T" },
      { value: "6", label: "6" },
      { value: "7", label: "7" },
      { value: "XXS", label: "XXS" },
      { value: "XS", label: "XS" },
      { value: "S", label: "S" },
    ],
  },
  {
    label: "Big Kid",
    options: [
      { value: "8", label: "8" },
      { value: "9", label: "9" },
      { value: "10", label: "10" },
      { value: "11", label: "11" },
      { value: "12", label: "12" },
      { value: "13", label: "13" },
      { value: "14", label: "14" },
      { value: "16+", label: "16+" },
      { value: "M", label: "M" },
      { value: "L", label: "L" },
      { value: "XL", label: "XL" },
      { value: "XXL", label: "XXL" },
    ],
  },
];

const kidShoes = [
  {
    label: "Baby",
    options: [
      { value: "preemie", label: "preemie" },
      { value: "nb", label: "nb" },
      { value: "0-3 mo", label: "0-3 mo" },
      { value: "3-6 mo", label: "3-6 mo" },
      { value: "6-12 mo", label: "6-12 mo" },
      { value: "12-18 mo", label: "12-18 mo" },
      { value: "18-24 mo", label: "18-24 mo" },
      { value: "12-24 mo", label: "12-24 mo" },
      { value: "0 Infant", label: "0 Infant" },
      { value: "1 Infant", label: "1 Infant" },
      { value: "2 Infant", label: "2 Infant" },
      { value: "3 Infant", label: "3 Infant" },
      { value: "3.5 Infant", label: "3.5 Infant" },
      { value: "4 Infant", label: "4 Infant" },
      { value: "4.5 Infant", label: "4.5 Infant" },
      { value: "5 Infant", label: "5 Infant" },
      { value: "5.5 Infant", label: "5.5 Infant" },
      { value: "6 Infant", label: "6 Infant" },
      { value: "6.5 Infant", label: "6.5 Infant" },
      { value: "7 Infant", label: "7 Infant" },
      { value: "One Size", label: "One Size" },
    ],
  },
  {
    label: "Toddler",
    options: [
      { value: "7.5 Toddler", label: "7.5 Toddler" },
      { value: "8 Toddler", label: "8 Toddler" },
      { value: "8.5 Toddler", label: "8.5 Toddler" },
      { value: "9 Toddler", label: "9 Toddler" },
      { value: "9.5 Toddler", label: "9.5 Toddler" },
      { value: "10 Toddler", label: "10 Toddler" },
      { value: "10.5 Toddler", label: "10.5 Toddler" },
      { value: "11 Toddler", label: "11 Toddler" },
      { value: "11.5 Toddler", label: "11.5 Toddler" },
    ],
  },
  {
    label: "Little Kid",
    options: [
      { value: "12 Little Kid", label: "12 Little Kid" },
      { value: "12.5 Little Kid", label: "12.5 Little Kid" },
      { value: "13 Little Kid", label: "13 Little Kid" },
      { value: "13.5 Little Kid", label: "13.5 Little Kid" },
      { value: "1 Little Kid", label: "1 Little Kid" },
      { value: "1.5 Little Kid", label: "1.5 Little Kid" },
    ],
  },
  {
    label: "Big Kid",
    options: [
      { value: "2 Big Kid", label: "2 Big Kid" },
      { value: "2.5 Big Kid", label: "2.5 Big Kid" },
      { value: "3 Big Kid", label: "3 Big Kid" },
      { value: "3.5 Big Kid", label: "3.5 Big Kid" },
      { value: "4 Big Kid", label: "4 Big Kid" },
      { value: "4.5 Big Kid", label: "4.5 Big Kid" },
      { value: "5 Big Kid", label: "5 Big Kid" },
      { value: "5.5 Big Kid", label: "5.5 Big Kid" },
      { value: "6 Big Kid", label: "6 Big Kid" },
      { value: "6.5 Big Kid", label: "6.5 Big Kid" },
      { value: "7 Big Kid", label: "7 Big Kid" },
    ],
  },
];

const kidAccessories = [
  {
    label: "Baby",
    options: [
      { value: "preemie", label: "preemie" },
      { value: "nb", label: "nb" },
      { value: "0-3 mo", label: "0-3 mo" },
      { value: "3-6 mo", label: "3-6 mo" },
      { value: "6-12 mo", label: "6-12 mo" },
      { value: "12-18 mo", label: "12-18 mo" },
      { value: "18-24 mo", label: "18-24 mo" },
    ],
  },
  {
    label: "Toddler",
    options: [
      { value: "2", label: "2" },
      { value: "2T", label: "2T" },
      { value: "3", label: "3" },
      { value: "3T", label: "3T" },
      { value: "4", label: "4" },
      { value: "4T", label: "4T" },
    ],
  },
  {
    label: "Little Kid",
    options: [
      { value: "5", label: "5" },
      { value: "5T", label: "5T" },
      { value: "6", label: "6" },
      { value: "7", label: "7" },
      { value: "XXS", label: "XXS" },
      { value: "XS", label: "XS" },
      { value: "S", label: "S" },
    ],
  },
  {
    label: "Big Kid",
    options: [
      { value: "8", label: "8" },
      { value: "9", label: "9" },
      { value: "10", label: "10" },
      { value: "12", label: "12" },
      { value: "14", label: "14" },
      { value: "16+", label: "16+" },
      { value: "M", label: "M" },
      { value: "L", label: "L" },
      { value: "XL", label: "XL" },
      { value: "XXL", label: "XXL" },
    ],
  },
  {
    label: "Unknown",
    options: [
      { value: "XS", label: "XS" },
      { value: "S", label: "S" },
      { value: "M", label: "M" },
      { value: "L", label: "L" },
      { value: "XL", label: "XL" },
      { value: "N/A", label: "N/A" },
    ],
  },
];

const childrensBooksToys = [
  {
    label: "Books & Toys",
    options: [
      { value: "One Size", label: "One Size" },
      { value: "N/A", label: "N/A" },
    ],
  },
];

const babyEssentials = [
  {
    label: "Baby Essentials",
    options: [
      { value: "preemie", label: "preemie" },
      { value: "nb", label: "nb" },
      { value: "0-3 mo", label: "0-3 mo" },
      { value: "3-6 mo", label: "3-6 mo" },
      { value: "6-12 mo", label: "6-12 mo" },
      { value: "12-18 mo", label: "12-18 mo" },
      { value: "18-24 mo", label: "18-24 mo" },
      { value: "XS", label: "XS" },
      { value: "S", label: "S" },
      { value: "M", label: "M" },
      { value: "L", label: "L" },
      { value: "XL", label: "XL" },
      { value: "One Size", label: "One Size" },
      { value: "N/A", label: "N/A" },
    ],
  },
];

const diaperBagsCarriers = [
  {
    label: "Diaper Bags & Carriers",
    options: [
      { value: "XS", label: "XS" },
      { value: "S", label: "S" },
      { value: "M", label: "M" },
      { value: "L", label: "L" },
      { value: "XL", label: "XL" },
      { value: "One Size", label: "One Size" },
      { value: "N/A", label: "N/A" },
    ],
  },
];

const ChildrenRoomNursery = [
  {
    label: "Room & Nursery",
    options: [
      { value: "One Size", label: "One Size" },
      { value: "N/A", label: "N/A" },
    ],
  },
];

const maternity = [
  {
    label: "Women",
    options: [
      { value: "0", label: "0" },
      { value: "00", label: "00" },
      { value: "2", label: "2" },
      { value: "4", label: "4" },
      { value: "6", label: "6" },
      { value: "8", label: "8" },
      { value: "10", label: "10" },
      { value: "12", label: "12" },
      { value: "14", label: "14" },
      { value: "16", label: "16" },
      { value: "18", label: "18" },
      { value: "20", label: "20" },
      { value: "22", label: "22" },
      { value: "23", label: "23" },
      { value: "24", label: "24" },
      { value: "25", label: "25" },
      { value: "26", label: "26" },
      { value: "27", label: "27" },
      { value: "28", label: "28" },
      { value: "29", label: "29" },
      { value: "30", label: "30" },
      { value: "31", label: "31" },
      { value: "32", label: "32" },
      { value: "33", label: "33" },
      { value: "34", label: "34" },
      { value: "35", label: "35" },
      { value: "36", label: "36" },
      { value: "XXS", label: "XXS" },
      { value: "XS", label: "XS" },
      { value: "S", label: "S" },
      { value: "M", label: "M" },
      { value: "L", label: "L" },
      { value: "XL", label: "XL" },
      { value: "XXL", label: "XXL" },
      { value: "One Size", label: "One Size" },
    ],
  },
];

const mamaClothing = [
  {
    label: "Standard",
    options: [
      { value: "00", label: "00" },
      { value: "0", label: "0" },
      { value: "2", label: "2" },
      { value: "4", label: "4" },
      { value: "6", label: "6" },
      { value: "8", label: "8" },
      { value: "10", label: "10" },
      { value: "12", label: "12" },
      { value: "23", label: "23" },
      { value: "24", label: "24" },
      { value: "25", label: "25" },
      { value: "26", label: "26" },
      { value: "27", label: "27" },
      { value: "28", label: "28" },
      { value: "29", label: "29" },
      { value: "30", label: "30" },
      { value: "31", label: "31" },
      { value: "XXS", label: "XXS" },
      { value: "XS", label: "XS" },
      { value: "S", label: "S" },
      { value: "M", label: "M" },
      { value: "L", label: "L" },
      { value: "XL", label: "XL" },
      { value: "XXL", label: "XXL" },
      { value: "One Size", label: "One Size" },
    ],
  },
  {
    label: "Plus",
    options: [
      { value: "14 Plus", label: "14 Plus" },
      { value: "14W", label: "14W" },
      { value: "16 Plus", label: "16 Plus" },
      { value: "16W", label: "16W" },
      { value: "18 Plus", label: "18 Plus" },
      { value: "18W", label: "18W" },
      { value: "20 Plus", label: "20 Plus" },
      { value: "20W", label: "20W" },
      { value: "22 Plus", label: "22 Plus" },
      { value: "22W", label: "22W" },
      { value: "24 Plus", label: "24 Plus" },
      { value: "24W", label: "24W" },
      { value: "26 Plus", label: "26 Plus" },
      { value: "26W", label: "26W" },
      { value: "28 Plus", label: "28 Plus" },
      { value: "28W", label: "28W" },
      { value: "30 Plus", label: "30 Plus" },
      { value: "30W", label: "30W" },
      { value: "32 Plus", label: "32 Plus" },
      { value: "32W", label: "32W" },
      { value: "33 Plus", label: "33 Plus" },
      { value: "34 Plus", label: "34 Plus" },
      { value: "36 Plus", label: "36 Plus" },
      { value: "0X", label: "0X" },
      { value: "1X", label: "1X" },
      { value: "2X", label: "2X" },
      { value: "3X", label: "3X" },
      { value: "4X", label: "4X" },
      { value: "5X", label: "5X" },
    ],
  },
  {
    label: "Petite",
    options: [
      { value: "00P", label: "00P" },
      { value: "0P", label: "0P" },
      { value: "2P", label: "2P" },
      { value: "4P", label: "4P" },
      { value: "6P", label: "6P" },
      { value: "8P", label: "8P" },
      { value: "10P", label: "10P" },
      { value: "12P", label: "12P" },
      { value: "14P", label: "14P" },
      { value: "16P", label: "16P" },
      { value: "18P", label: "18P" },
      { value: "24P", label: "24P" },
      { value: "25P", label: "25P" },
      { value: "26P", label: "26P" },
      { value: "27P", label: "27P" },
      { value: "28P", label: "28P" },
      { value: "29P", label: "29P" },
      { value: "30P", label: "30P" },
      { value: "31P", label: "31P" },
      { value: "32P", label: "32P" },
      { value: "XXSP", label: "XXSP" },
      { value: "XSP", label: "XSP" },
      { value: "SP", label: "SP" },
      { value: "MP", label: "MP" },
      { value: "LP", label: "LP" },
    ],
  },
];

const MamaShoes = [
  {
    label: "Women",
    options: [
      { value: "5", label: "5" },
      { value: "5.5", label: "5.5" },
      { value: "6", label: "6" },
      { value: "6.5", label: "6.5" },
      { value: "7", label: "7" },
      { value: "7.5", label: "7.5" },
      { value: "8", label: "8" },
      { value: "8.5", label: "8.5" },
      { value: "9", label: "9" },
      { value: "9.5", label: "9.5" },
      { value: "10", label: "10" },
      { value: "10.5", label: "10.5" },
      { value: "11", label: "11" },
      { value: "11.5", label: "11.5" },
      { value: "12", label: "12" },
    ],
  },
];

const MamaAccessories = [
  {
    label: "Women",
    options: [
      { value: "XS", label: "XS" },
      { value: "S", label: "S" },
      { value: "M", label: "M" },
      { value: "L", label: "L" },
      { value: "XL", label: "XL" },
      { value: "One Size", label: "One Size" },
    ],
  },
];

export const SizeOptions = {
  "Kid Clothing": kidClothing,
  "Kid Shoes": kidShoes,
  "Kid Accessories": kidAccessories,
  "Children's Books & Toys": childrensBooksToys,
  "Baby Essentials": babyEssentials,
  "Diaper Bags & Carries": diaperBagsCarriers,
  "Children's Room & Nursery": ChildrenRoomNursery,
  Maternity: maternity,
  "Mama Clothing": mamaClothing,
  "Mama Shoes": MamaShoes,
  "Mama Accessories": MamaAccessories,
};

export const brands = [
  {
    label: "1+ In The Family",
    value: "1+ In The Family",
  },
  {
    label: "3 Pommes",
    value: "3 Pommes",
  },
  {
    label: "4moms",
    value: "4moms",
  },
  {
    label: "7 A.M. Enfant",
    value: "7 A.M. Enfant",
  },
  {
    label: "7 for All Mankind",
    value: "7 for All Mankind",
  },
  {
    label: "90 Degree By Reflex",
    value: "90 Degree By Reflex",
  },
  {
    label: "Abercrombie",
    value: "Abercrombie",
  },
  {
    label: "A.Bird",
    value: "A.Bird",
  },
  {
    label: "Ace & Jig",
    value: "Ace & Jig",
  },
  {
    label: "Acne Studios",
    value: "Acne Studios",
  },
  {
    label: "Adam + Yve",
    value: "Adam + Yve",
  },
  {
    label: "Adelaide Original",
    value: "Adelaide Original",
  },
  {
    label: "Adelisa & Co.",
    value: "Adelisa & Co.",
  },
  {
    label: "Aden + Anais",
    value: "Aden + Anais",
  },
  {
    label: "Adidas",
    value: "Adidas",
  },
  {
    label: "Adorable Essentials",
    value: "Adorable Essentials",
  },
  {
    label: "Adventure Wear",
    value: "Adventure Wear",
  },
  {
    label: "Aerie",
    value: "Aerie",
  },
  {
    label: "Aeropostale",
    value: "Aeropostale",
  },
  {
    label: "Agatha Cub",
    value: "Agatha Cub",
  },
  {
    label: "AG Jeans",
    value: "AG Jeans",
  },
  {
    label: "Agolde",
    value: "Agolde",
  },
  {
    label: "Ahoy Amigo",
    value: "Ahoy Amigo",
  },
  {
    label: "Aigle",
    value: "Aigle",
  },
  {
    label: "Aisabobo",
    value: "Aisabobo",
  },
  {
    label: "AKID",
    value: "AKID",
  },
  {
    label: "Alba of Denmark",
    value: "Alba of Denmark",
  },
  {
    label: "Albion",
    value: "Albion",
  },
  {
    label: "Aldo",
    value: "Aldo",
  },
  {
    label: "Alex Toys",
    value: "Alex Toys",
  },
  {
    label: "Alice + Ames",
    value: "Alice + Ames",
  },
  {
    label: "Alice + Olivia",
    value: "Alice + Olivia",
  },
  {
    label: "Alimrose",
    value: "Alimrose",
  },
  {
    label: "Allbirds",
    value: "Allbirds",
  },
  {
    label: "All In Motion",
    value: "All In Motion",
  },
  {
    label: "Allsaints",
    value: "Allsaints",
  },
  {
    label: "Alo Yoga",
    value: "Alo Yoga",
  },
  {
    label: "Alpine Baby Co",
    value: "Alpine Baby Co",
  },
  {
    label: "Altar'd State",
    value: "Altar'd State",
  },
  {
    label: "Alternative",
    value: "Alternative",
  },
  {
    label: "Alvababy",
    value: "Alvababy",
  },
  {
    label: "Amae Co.",
    value: "Amae Co.",
  },
  {
    label: "American Apparel",
    value: "American Apparel",
  },
  {
    label: "American Eagle",
    value: "American Eagle",
  },
  {
    label: "American Giant",
    value: "American Giant",
  },
  {
    label: "American Girl",
    value: "American Girl",
  },
  {
    label: "American Princess",
    value: "American Princess",
  },
  {
    label: "American Rag",
    value: "American Rag",
  },
  {
    label: "Ammehoela",
    value: "Ammehoela",
  },
  {
    label: "Ampersand Ave",
    value: "Ampersand Ave",
  },
  {
    label: "Amuse Society",
    value: "Amuse Society",
  },
  {
    label: "Anavini",
    value: "Anavini",
  },
  {
    label: "Andy & Evan",
    value: "Andy & Evan",
  },
  {
    label: "A New Day",
    value: "A New Day",
  },
  {
    label: "Angel Dear",
    value: "Angel Dear",
  },
  {
    label: "Angelitos",
    value: "Angelitos",
  },
  {
    label: "Animal Planet",
    value: "Animal Planet",
  },
  {
    label: "Anine Bing",
    value: "Anine Bing",
  },
  {
    label: "Annabelle",
    value: "Annabelle",
  },
  {
    label: "AnnLoren",
    value: "AnnLoren",
  },
  {
    label: "Ann Taylor",
    value: "Ann Taylor",
  },
  {
    label: "Anthem of the Ants",
    value: "Anthem of the Ants",
  },
  {
    label: "Anthropologie",
    value: "Anthropologie",
  },
  {
    label: "A Pea in the Pod",
    value: "A Pea in the Pod",
  },
  {
    label: "Aphorism",
    value: "Aphorism",
  },
  {
    label: "Apolina",
    value: "Apolina",
  },
  {
    label: "Appaman",
    value: "Appaman",
  },
  {
    label: "AppleCheeks",
    value: "AppleCheeks",
  },
  {
    label: "April Showers",
    value: "April Showers",
  },
  {
    label: "Aquarium Kidz",
    value: "Aquarium Kidz",
  },
  {
    label: "Arctix",
    value: "Arctix",
  },
  {
    label: "Ariat",
    value: "Ariat",
  },
  {
    label: "Arizona Jean Co",
    value: "Arizona Jean Co",
  },
  {
    label: "Arlo & Imus",
    value: "Arlo & Imus",
  },
  {
    label: "Armani Baby",
    value: "Armani Baby",
  },
  {
    label: "ARQ",
    value: "ARQ",
  },
  {
    label: "Arsene Et Les Pipelettes",
    value: "Arsene Et Les Pipelettes",
  },
  {
    label: "Art Class",
    value: "Art Class",
  },
  {
    label: "Art & Eden",
    value: "Art & Eden",
  },
  {
    label: "Artesania Granlei",
    value: "Artesania Granlei",
  },
  {
    label: "Asics",
    value: "Asics",
  },
  {
    label: "ASOS",
    value: "ASOS",
  },
  {
    label: "Aster",
    value: "Aster",
  },
  {
    label: "Astr the Label",
    value: "Astr the Label",
  },
  {
    label: "Athleta",
    value: "Athleta",
  },
  {
    label: "Athleta Girl",
    value: "Athleta Girl",
  },
  {
    label: "Athletic Works",
    value: "Athletic Works",
  },
  {
    label: "Atsuyo et Akiko",
    value: "Atsuyo et Akiko",
  },
  {
    label: "August River",
    value: "August River",
  },
  {
    label: "Ava Loves Olli",
    value: "Ava Loves Olli",
  },
  {
    label: "Avia",
    value: "Avia",
  },
  {
    label: "Aviator Nation",
    value: "Aviator Nation",
  },
  {
    label: "Aymara",
    value: "Aymara",
  },
  {
    label: "Babidu",
    value: "Babidu",
  },
  {
    label: "Babies R Us",
    value: "Babies R Us",
  },
  {
    label: "Baby Alive",
    value: "Baby Alive",
  },
  {
    label: "BabyBjorn",
    value: "BabyBjorn",
  },
  {
    label: "Baby Bling",
    value: "Baby Bling",
  },
  {
    label: "Baby Boden",
    value: "Baby Boden",
  },
  {
    label: "BabyBreez",
    value: "BabyBreez",
  },
  {
    label: "Babycottons",
    value: "Babycottons",
  },
  {
    label: "Baby Deedee",
    value: "Baby Deedee",
  },
  {
    label: "Baby Deer",
    value: "Baby Deer",
  },
  {
    label: "Baby Dior",
    value: "Baby Dior",
  },
  {
    label: "Baby Einstein",
    value: "Baby Einstein",
  },
  {
    label: "Babyface",
    value: "Babyface",
  },
  {
    label: "Babyhawk",
    value: "Babyhawk",
  },
  {
    label: "Baby Jives",
    value: "Baby Jives",
  },
  {
    label: "Baby Jogger",
    value: "Baby Jogger",
  },
  {
    label: "Baby K'Tan",
    value: "Baby K'Tan",
  },
  {
    label: "Baby Laundry",
    value: "Baby Laundry",
  },
  {
    label: "Baby Lulu",
    value: "Baby Lulu",
  },
  {
    label: "Baby Merlin",
    value: "Baby Merlin",
  },
  {
    label: "Babymoov",
    value: "Babymoov",
  },
  {
    label: "Baby Nay",
    value: "Baby Nay",
  },
  {
    label: "Baby Sara",
    value: "Baby Sara",
  },
  {
    label: "Babysoy",
    value: "Babysoy",
  },
  {
    label: "babysprouts",
    value: "babysprouts",
  },
  {
    label: "Bailey Boys",
    value: "Bailey Boys",
  },
  {
    label: "Bailey's Blossoms",
    value: "Bailey's Blossoms",
  },
  {
    label: "Balabala Kids",
    value: "Balabala Kids",
  },
  {
    label: "Balboa Baby",
    value: "Balboa Baby",
  },
  {
    label: "Balera",
    value: "Balera",
  },
  {
    label: "Ballet Rosa",
    value: "Ballet Rosa",
  },
  {
    label: "Baltogs",
    value: "Baltogs",
  },
  {
    label: "Bamberoo",
    value: "Bamberoo",
  },
  {
    label: "Bambino Mio",
    value: "Bambino Mio",
  },
  {
    label: "Bamboo Baby",
    value: "Bamboo Baby",
  },
  {
    label: "Banana Republic",
    value: "Banana Republic",
  },
  {
    label: "Banana Valentine",
    value: "Banana Valentine",
  },
  {
    label: "Bandier",
    value: "Bandier",
  },
  {
    label: "Bandy Button",
    value: "Bandy Button",
  },
  {
    label: "Bang Bang Copenhagen",
    value: "Bang Bang Copenhagen",
  },
  {
    label: "Bannor Toys",
    value: "Bannor Toys",
  },
  {
    label: "Barbie",
    value: "Barbie",
  },
  {
    label: "Batoko",
    value: "Batoko",
  },
  {
    label: "BB Dakota",
    value: "BB Dakota",
  },
  {
    label: "BCBG",
    value: "BCBG",
  },
  {
    label: "Beach Riot",
    value: "Beach Riot",
  },
  {
    label: "Bearpaw",
    value: "Bearpaw",
  },
  {
    label: "Beau Hudson",
    value: "Beau Hudson",
  },
  {
    label: "Beau Loves",
    value: "Beau Loves",
  },
  {
    label: "Beautees",
    value: "Beautees",
  },
  {
    label: "Bebe",
    value: "Bebe",
  },
  {
    label: "Bebe De Pino",
    value: "Bebe De Pino",
  },
  {
    label: "Bebenca Organics",
    value: "Bebenca Organics",
  },
  {
    label: "Beberlis",
    value: "Beberlis",
  },
  {
    label: "Beco",
    value: "Beco",
  },
  {
    label: "Bed Stu",
    value: "Bed Stu",
  },
  {
    label: "Beeko",
    value: "Beeko",
  },
  {
    label: "Beetlejuice",
    value: "Beetlejuice",
  },
  {
    label: "Beet World",
    value: "Beet World",
  },
  {
    label: "Belevation",
    value: "Belevation",
  },
  {
    label: "Bella Bambina",
    value: "Bella Bambina",
  },
  {
    label: "Bella Bliss",
    value: "Bella Bliss",
  },
  {
    label: "Bella & Lace",
    value: "Bella & Lace",
  },
  {
    label: "Bella & Omi",
    value: "Bella & Omi",
  },
  {
    label: "Bellybutton",
    value: "Bellybutton",
  },
  {
    label: "Benetton",
    value: "Benetton",
  },
  {
    label: "Ben Sherman",
    value: "Ben Sherman",
  },
  {
    label: "Bensimon",
    value: "Bensimon",
  },
  {
    label: "Bentgo Box",
    value: "Bentgo Box",
  },
  {
    label: "Bercot Art and Soul",
    value: "Bercot Art and Soul",
  },
  {
    label: "Bestaroo",
    value: "Bestaroo",
  },
  {
    label: "Betabrand",
    value: "Betabrand",
  },
  {
    label: "Betsey Johnson",
    value: "Betsey Johnson",
  },
  {
    label: "Beya Made",
    value: "Beya Made",
  },
  {
    label: "Beyond Yoga",
    value: "Beyond Yoga",
  },
  {
    label: "Bijou Sauvage",
    value: "Bijou Sauvage",
  },
  {
    label: "Billabong",
    value: "Billabong",
  },
  {
    label: "Billie Blooms",
    value: "Billie Blooms",
  },
  {
    label: "Billieblush",
    value: "Billieblush",
  },
  {
    label: "Billybandit",
    value: "Billybandit",
  },
  {
    label: "BILLY Footwear",
    value: "BILLY Footwear",
  },
  {
    label: "Binky Bro",
    value: "Binky Bro",
  },
  {
    label: "BirdRock Baby",
    value: "BirdRock Baby",
  },
  {
    label: "Birkenstock",
    value: "Birkenstock",
  },
  {
    label: "Biscotti",
    value: "Biscotti",
  },
  {
    label: "Bit'z Kids",
    value: "Bit'z Kids",
  },
  {
    label: "Blabla",
    value: "Blabla",
  },
  {
    label: "BlankNYC",
    value: "BlankNYC",
  },
  {
    label: "Bloch",
    value: "Bloch",
  },
  {
    label: "Blowfish",
    value: "Blowfish",
  },
  {
    label: "Blueberi Boulevard",
    value: "Blueberi Boulevard",
  },
  {
    label: "Blueberry Bay",
    value: "Blueberry Bay",
  },
  {
    label: "Blueberry Diapers",
    value: "Blueberry Diapers",
  },
  {
    label: "Blue Water Dancewear",
    value: "Blue Water Dancewear",
  },
  {
    label: "Blu Monet",
    value: "Blu Monet",
  },
  {
    label: "Blu Pony Vintage",
    value: "Blu Pony Vintage",
  },
  {
    label: "Boba",
    value: "Boba",
  },
  {
    label: "Bobby.G",
    value: "Bobby.G",
  },
  {
    label: "Bobeau",
    value: "Bobeau",
  },
  {
    label: "Bobo Choses",
    value: "Bobo Choses",
  },
  {
    label: "Boboli",
    value: "Boboli",
  },
  {
    label: "Bobux",
    value: "Bobux",
  },
  {
    label: "Boden",
    value: "Boden",
  },
  {
    label: "Body Glove",
    value: "Body Glove",
  },
  {
    label: "Body Wrappers",
    value: "Body Wrappers",
  },
  {
    label: "Bogg Bag",
    value: "Bogg Bag",
  },
  {
    label: "Bogs",
    value: "Bogs",
  },
  {
    label: "Bohme",
    value: "Bohme",
  },
  {
    label: "Bombas",
    value: "Bombas",
  },
  {
    label: "Bon Bebe",
    value: "Bon Bebe",
  },
  {
    label: "Bonds",
    value: "Bonds",
  },
  {
    label: "Bonnie Jean",
    value: "Bonnie Jean",
  },
  {
    label: "Bonpoint",
    value: "Bonpoint",
  },
  {
    label: "Bonton",
    value: "Bonton",
  },
  {
    label: "Boon",
    value: "Boon",
  },
  {
    label: "Born",
    value: "Born",
  },
  {
    label: "Bourgeois Bebe",
    value: "Bourgeois Bebe",
  },
  {
    label: "Boyds Bears",
    value: "Boyds Bears",
  },
  {
    label: "Boy+Girl",
    value: "Boy+Girl",
  },
  {
    label: "Boyish",
    value: "Boyish",
  },
  {
    label: "Boy Wonder",
    value: "Boy Wonder",
  },
  {
    label: "BP.",
    value: "BP.",
  },
  {
    label: "Brandy Melville",
    value: "Brandy Melville",
  },
  {
    label: "Brass Razoo",
    value: "Brass Razoo",
  },
  {
    label: "Brave Little Ones",
    value: "Brave Little Ones",
  },
  {
    label: "Breyer",
    value: "Breyer",
  },
  {
    label: "Briar Baby",
    value: "Briar Baby",
  },
  {
    label: "Brighton",
    value: "Brighton",
  },
  {
    label: "Bright Starts",
    value: "Bright Starts",
  },
  {
    label: "Britax",
    value: "Britax",
  },
  {
    label: "Broken Tricycle",
    value: "Broken Tricycle",
  },
  {
    label: "Brooklyn + Fifth",
    value: "Brooklyn + Fifth",
  },
  {
    label: "Brooks",
    value: "Brooks",
  },
  {
    label: "Brooks Brothers",
    value: "Brooks Brothers",
  },
  {
    label: "Btween",
    value: "Btween",
  },
  {
    label: "BUCK&BAA",
    value: "BUCK&BAA",
  },
  {
    label: "Buddha Babe",
    value: "Buddha Babe",
  },
  {
    label: "Bugaboo",
    value: "Bugaboo",
  },
  {
    label: "Buho",
    value: "Buho",
  },
  {
    label: "Build-A-Bear",
    value: "Build-A-Bear",
  },
  {
    label: "Bumbelou",
    value: "Bumbelou",
  },
  {
    label: "bumGenius",
    value: "bumGenius",
  },
  {
    label: "Bumkins",
    value: "Bumkins",
  },
  {
    label: "Bums & Roses",
    value: "Bums & Roses",
  },
  {
    label: "Bundle Of Joy",
    value: "Bundle Of Joy",
  },
  {
    label: "Bunny Knots",
    value: "Bunny Knots",
  },
  {
    label: "Burberry",
    value: "Burberry",
  },
  {
    label: "Burton",
    value: "Burton",
  },
  {
    label: "Burt's Bees",
    value: "Burt's Bees",
  },
  {
    label: "Butter",
    value: "Butter",
  },
  {
    label: "By Billie",
    value: "By Billie",
  },
  {
    label: "Cabana Life",
    value: "Cabana Life",
  },
  {
    label: "Cabi",
    value: "Cabi",
  },
  {
    label: "Cach Cach",
    value: "Cach Cach",
  },
  {
    label: "Caden Lane",
    value: "Caden Lane",
  },
  {
    label: "Cake Maternity",
    value: "Cake Maternity",
  },
  {
    label: "Cakewalk",
    value: "Cakewalk",
  },
  {
    label: "Calia by Carrie Underwood",
    value: "Calia by Carrie Underwood",
  },
  {
    label: "Calico Critters",
    value: "Calico Critters",
  },
  {
    label: "Californian Vintage",
    value: "Californian Vintage",
  },
  {
    label: "Calvin Klein",
    value: "Calvin Klein",
  },
  {
    label: "Camp Brand Goods",
    value: "Camp Brand Goods",
  },
  {
    label: "Camp Collection",
    value: "Camp Collection",
  },
  {
    label: "Camper",
    value: "Camper",
  },
  {
    label: "Canada Goose",
    value: "Canada Goose",
  },
  {
    label: "Candy Kirby Designs",
    value: "Candy Kirby Designs",
  },
  {
    label: "Candylab",
    value: "Candylab",
  },
  {
    label: "Cape Clogs",
    value: "Cape Clogs",
  },
  {
    label: "Capezio",
    value: "Capezio",
  },
  {
    label: "Caramel Baby & Child",
    value: "Caramel Baby & Child",
  },
  {
    label: "Carbon Soldier",
    value: "Carbon Soldier",
  },
  {
    label: "Carhartt",
    value: "Carhartt",
  },
  {
    label: "Cariuma",
    value: "Cariuma",
  },
  {
    label: "Carlijnq",
    value: "Carlijnq",
  },
  {
    label: "Carlymegan",
    value: "Carlymegan",
  },
  {
    label: "Caroline Bosmans",
    value: "Caroline Bosmans",
  },
  {
    label: "Caroline Kate",
    value: "Caroline Kate",
  },
  {
    label: "Carousel Designs",
    value: "Carousel Designs",
  },
  {
    label: "Carriage Boutiques",
    value: "Carriage Boutiques",
  },
  {
    label: "Carter's",
    value: "Carter's",
  },
  {
    label: "Cash & Co.",
    value: "Cash & Co.",
  },
  {
    label: "Caslon",
    value: "Caslon",
  },
  {
    label: "Castles & Crowns",
    value: "Castles & Crowns",
  },
  {
    label: "Catherine Malandrino",
    value: "Catherine Malandrino",
  },
  {
    label: "Cath Kidston",
    value: "Cath Kidston",
  },
  {
    label: "Catimini",
    value: "Catimini",
  },
  {
    label: "Cat & Jack",
    value: "Cat & Jack",
  },
  {
    label: "CC Beanies",
    value: "CC Beanies",
  },
  {
    label: "C&C California",
    value: "C&C California",
  },
  {
    label: "Cecil and Lou",
    value: "Cecil and Lou",
  },
  {
    label: "Chaco",
    value: "Chaco",
  },
  {
    label: "Champion",
    value: "Champion",
  },
  {
    label: "Chandamama",
    value: "Chandamama",
  },
  {
    label: "Chanel",
    value: "Chanel",
  },
  {
    label: "Chaps",
    value: "Chaps",
  },
  {
    label: "Chark Attack Threads",
    value: "Chark Attack Threads",
  },
  {
    label: "Charlie Banana",
    value: "Charlie Banana",
  },
  {
    label: "Charlie Rocket",
    value: "Charlie Rocket",
  },
  {
    label: "Charlotte Russe",
    value: "Charlotte Russe",
  },
  {
    label: "Charming Charlie",
    value: "Charming Charlie",
  },
  {
    label: "Chaser",
    value: "Chaser",
  },
  {
    label: "Chasing Fireflies",
    value: "Chasing Fireflies",
  },
  {
    label: "Chasing Oso",
    value: "Chasing Oso",
  },
  {
    label: "Chasing Rivers",
    value: "Chasing Rivers",
  },
  {
    label: "Cheeky Face",
    value: "Cheeky Face",
  },
  {
    label: "Cheeky Plum",
    value: "Cheeky Plum",
  },
  {
    label: "Cheer Fantastic",
    value: "Cheer Fantastic",
  },
  {
    label: "Cherokee",
    value: "Cherokee",
  },
  {
    label: "Chicco",
    value: "Chicco",
  },
  {
    label: "Chick Pea",
    value: "Chick Pea",
  },
  {
    label: "Chico's",
    value: "Chico's",
  },
  {
    label: "Childhood + Rising",
    value: "Childhood + Rising",
  },
  {
    label: "Childhoods",
    value: "Childhoods",
  },
  {
    label: "Childrenchic",
    value: "Childrenchic",
  },
  {
    label: "Children of the Tribe",
    value: "Children of the Tribe",
  },
  {
    label: "Children's Place",
    value: "Children's Place",
  },
  {
    label: "Chloe",
    value: "Chloe",
  },
  {
    label: "Chocolate Soup",
    value: "Chocolate Soup",
  },
  {
    label: "Chooze",
    value: "Chooze",
  },
  {
    label: "Christian Siriano",
    value: "Christian Siriano",
  },
  {
    label: "Chubbies",
    value: "Chubbies",
  },
  {
    label: "Chus",
    value: "Chus",
  },
  {
    label: "Cienta",
    value: "Cienta",
  },
  {
    label: "Circo",
    value: "Circo",
  },
  {
    label: "Citizens of Humanity",
    value: "Citizens of Humanity",
  },
  {
    label: "City Mouse",
    value: "City Mouse",
  },
  {
    label: "Claire & Charlie",
    value: "Claire & Charlie",
  },
  {
    label: "Clare V.",
    value: "Clare V.",
  },
  {
    label: "Clare Vivier",
    value: "Clare Vivier",
  },
  {
    label: "Clarks",
    value: "Clarks",
  },
  {
    label: "Classic Whimsy",
    value: "Classic Whimsy",
  },
  {
    label: "Clek",
    value: "Clek",
  },
  {
    label: "Cloth-eez",
    value: "Cloth-eez",
  },
  {
    label: "Cloth & Stone",
    value: "Cloth & Stone",
  },
  {
    label: "Cloud Island",
    value: "Cloud Island",
  },
  {
    label: "Clover & Birch",
    value: "Clover & Birch",
  },
  {
    label: "Coach",
    value: "Coach",
  },
  {
    label: "Coal",
    value: "Coal",
  },
  {
    label: "Coccoli",
    value: "Coccoli",
  },
  {
    label: "Coco Blanc",
    value: "Coco Blanc",
  },
  {
    label: "CocoBoxi",
    value: "CocoBoxi",
  },
  {
    label: "Cocove Swim",
    value: "Cocove Swim",
  },
  {
    label: "Coco Village",
    value: "Coco Village",
  },
  {
    label: "Cole Haan",
    value: "Cole Haan",
  },
  {
    label: "Collegien",
    value: "Collegien",
  },
  {
    label: "Colored Organics",
    value: "Colored Organics",
  },
  {
    label: "Columbia",
    value: "Columbia",
  },
  {
    label: "Comotomo",
    value: "Comotomo",
  },
  {
    label: "Condor",
    value: "Condor",
  },
  {
    label: "Converse",
    value: "Converse",
  },
  {
    label: "Coolibar",
    value: "Coolibar",
  },
  {
    label: "Copper Key",
    value: "Copper Key",
  },
  {
    label: "Copper Pearl",
    value: "Copper Pearl",
  },
  {
    label: "Corky's Kids",
    value: "Corky's Kids",
  },
  {
    label: "Cosilana",
    value: "Cosilana",
  },
  {
    label: "Cotton On",
    value: "Cotton On",
  },
  {
    label: "Cozys",
    value: "Cozys",
  },
  {
    label: "Craft + Flow",
    value: "Craft + Flow",
  },
  {
    label: "Crate&Kids",
    value: "Crate&Kids",
  },
  {
    label: "Crayola",
    value: "Crayola",
  },
  {
    label: "Crazy 8's",
    value: "Crazy 8's",
  },
  {
    label: "Creamie",
    value: "Creamie",
  },
  {
    label: "Crewcuts",
    value: "Crewcuts",
  },
  {
    label: "Crew & Lu",
    value: "Crew & Lu",
  },
  {
    label: "Crocs",
    value: "Crocs",
  },
  {
    label: "Crown & Ivy",
    value: "Crown & Ivy",
  },
  {
    label: "Cuddle + Kind",
    value: "Cuddle + Kind",
  },
  {
    label: "Cupcakes & Pastries",
    value: "Cupcakes & Pastries",
  },
  {
    label: "Cupshe",
    value: "Cupshe",
  },
  {
    label: "Cuyana",
    value: "Cuyana",
  },
  {
    label: "Cybex",
    value: "Cybex",
  },
  {
    label: "Cynthia Rowley",
    value: "Cynthia Rowley",
  },
  {
    label: "Dabbawalla",
    value: "Dabbawalla",
  },
  {
    label: "Dagmar Daley",
    value: "Dagmar Daley",
  },
  {
    label: "Damalli",
    value: "Damalli",
  },
  {
    label: "Daniel Rainn",
    value: "Daniel Rainn",
  },
  {
    label: "Danskin",
    value: "Danskin",
  },
  {
    label: "Dansko",
    value: "Dansko",
  },
  {
    label: "Danznmotion",
    value: "Danznmotion",
  },
  {
    label: "Daughter Co",
    value: "Daughter Co",
  },
  {
    label: "Daydreamer",
    value: "Daydreamer",
  },
  {
    label: "DC",
    value: "DC",
  },
  {
    label: "DC Comics",
    value: "DC Comics",
  },
  {
    label: "Decaf Plush",
    value: "Decaf Plush",
  },
  {
    label: "Desigual",
    value: "Desigual",
  },
  {
    label: "Destira",
    value: "Destira",
  },
  {
    label: "Deux Par Deux",
    value: "Deux Par Deux",
  },
  {
    label: "Devon Aire",
    value: "Devon Aire",
  },
  {
    label: "Diane Von Furstenberg",
    value: "Diane Von Furstenberg",
  },
  {
    label: "Diaper Dude",
    value: "Diaper Dude",
  },
  {
    label: "Dickies",
    value: "Dickies",
  },
  {
    label: "Diesel",
    value: "Diesel",
  },
  {
    label: "Disco Panda",
    value: "Disco Panda",
  },
  {
    label: "Disney",
    value: "Disney",
  },
  {
    label: "Djeco",
    value: "Djeco",
  },
  {
    label: "DKNY",
    value: "DKNY",
  },
  {
    label: "DL1961",
    value: "DL1961",
  },
  {
    label: "DockATot",
    value: "DockATot",
  },
  {
    label: "Dodo Banana",
    value: "Dodo Banana",
  },
  {
    label: "Doe A Dear",
    value: "Doe A Dear",
  },
  {
    label: "Dolce & Gabbana",
    value: "Dolce & Gabbana",
  },
  {
    label: "Dolce Vita",
    value: "Dolce Vita",
  },
  {
    label: "Dolfin",
    value: "Dolfin",
  },
  {
    label: "Dollcake",
    value: "Dollcake",
  },
  {
    label: "Dondolo",
    value: "Dondolo",
  },
  {
    label: "Donsje",
    value: "Donsje",
  },
  {
    label: "Dooney & Bourke",
    value: "Dooney & Bourke",
  },
  {
    label: "Dot Dot Smile",
    value: "Dot Dot Smile",
  },
  {
    label: "Dotti Shop",
    value: "Dotti Shop",
  },
  {
    label: "Dotty Dungarees",
    value: "Dotty Dungarees",
  },
  {
    label: "Dover Saddlery",
    value: "Dover Saddlery",
  },
  {
    label: "Dr. Brown's",
    value: "Dr. Brown's",
  },
  {
    label: "Dreamland Baby Co.",
    value: "Dreamland Baby Co.",
  },
  {
    label: "Dreamworks",
    value: "Dreamworks",
  },
  {
    label: "Dr. Martens",
    value: "Dr. Martens",
  },
  {
    label: "Dr. Scholl's",
    value: "Dr. Scholl's",
  },
  {
    label: "Dr. Seuss",
    value: "Dr. Seuss",
  },
  {
    label: "DSG",
    value: "DSG",
  },
  {
    label: "Dublin",
    value: "Dublin",
  },
  {
    label: "Duchess & Fox",
    value: "Duchess & Fox",
  },
  {
    label: "Duchess & Lion",
    value: "Duchess & Lion",
  },
  {
    label: "Dudesndolls",
    value: "Dudesndolls",
  },
  {
    label: "DUNS",
    value: "DUNS",
  },
  {
    label: "Durango",
    value: "Durango",
  },
  {
    label: "Dwell Studio",
    value: "Dwell Studio",
  },
  {
    label: "Eadaie",
    value: "Eadaie",
  },
  {
    label: "Eberjey",
    value: "Eberjey",
  },
  {
    label: "Eddie Bauer",
    value: "Eddie Bauer",
  },
  {
    label: "Edgehill Collection",
    value: "Edgehill Collection",
  },
  {
    label: "eeBoo",
    value: "eeBoo",
  },
  {
    label: "Egg",
    value: "Egg",
  },
  {
    label: "Eileen Fisher",
    value: "Eileen Fisher",
  },
  {
    label: "E-Land",
    value: "E-Land",
  },
  {
    label: "Eleanor Rose",
    value: "Eleanor Rose",
  },
  {
    label: "Elegant Baby",
    value: "Elegant Baby",
  },
  {
    label: "Elephantito",
    value: "Elephantito",
  },
  {
    label: "Ele Story",
    value: "Ele Story",
  },
  {
    label: "Eleven Paris",
    value: "Eleven Paris",
  },
  {
    label: "Eli",
    value: "Eli",
  },
  {
    label: "Eliane Et Lena",
    value: "Eliane Et Lena",
  },
  {
    label: "Eliza Cate and Co",
    value: "Eliza Cate and Co",
  },
  {
    label: "Eliza J",
    value: "Eliza J",
  },
  {
    label: "Eliza James",
    value: "Eliza James",
  },
  {
    label: "Ella Dane",
    value: "Ella Dane",
  },
  {
    label: "Ella Moss",
    value: "Ella Moss",
  },
  {
    label: "Eloquii",
    value: "Eloquii",
  },
  {
    label: "Embe",
    value: "Embe",
  },
  {
    label: "Emerald August",
    value: "Emerald August",
  },
  {
    label: "Emile Et Ida",
    value: "Emile Et Ida",
  },
  {
    label: "Emily and Oliver",
    value: "Emily and Oliver",
  },
  {
    label: "Emma Jane",
    value: "Emma Jane",
  },
  {
    label: "Emu",
    value: "Emu",
  },
  {
    label: "Epic Threads",
    value: "Epic Threads",
  },
  {
    label: "Equinavia",
    value: "Equinavia",
  },
  {
    label: "Ergobaby",
    value: "Ergobaby",
  },
  {
    label: "Eric Javits",
    value: "Eric Javits",
  },
  {
    label: "Esembly",
    value: "Esembly",
  },
  {
    label: "Esme",
    value: "Esme",
  },
  {
    label: "Essikakids",
    value: "Essikakids",
  },
  {
    label: "Ettie & H",
    value: "Ettie & H",
  },
  {
    label: "Evenflo",
    value: "Evenflo",
  },
  {
    label: "Evereve",
    value: "Evereve",
  },
  {
    label: "Ever Iyla",
    value: "Ever Iyla",
  },
  {
    label: "Everlane",
    value: "Everlane",
  },
  {
    label: "Evie's Closet",
    value: "Evie's Closet",
  },
  {
    label: "Evolve",
    value: "Evolve",
  },
  {
    label: "Evy's Tree",
    value: "Evy's Tree",
  },
  {
    label: "Express",
    value: "Express",
  },
  {
    label: "Eyelet & Ivy",
    value: "Eyelet & Ivy",
  },
  {
    label: "FAAS Design",
    value: "FAAS Design",
  },
  {
    label: "Fab Kids",
    value: "Fab Kids",
  },
  {
    label: "Fable & Ford",
    value: "Fable & Ford",
  },
  {
    label: "Fabletics",
    value: "Fabletics",
  },
  {
    label: "Fairechild",
    value: "Fairechild",
  },
  {
    label: "Fair Felix",
    value: "Fair Felix",
  },
  {
    label: "Faithfull the Brand",
    value: "Faithfull the Brand",
  },
  {
    label: "Fantaisie Kids",
    value: "Fantaisie Kids",
  },
  {
    label: "FAO Schwarz",
    value: "FAO Schwarz",
  },
  {
    label: "Fat Brain Toys",
    value: "Fat Brain Toys",
  },
  {
    label: "Fatface",
    value: "Fatface",
  },
  {
    label: "Fawn Design",
    value: "Fawn Design",
  },
  {
    label: "Feather 4 Arrow",
    value: "Feather 4 Arrow",
  },
  {
    label: "Feather Baby",
    value: "Feather Baby",
  },
  {
    label: "Feltman Brothers",
    value: "Feltman Brothers",
  },
  {
    label: "Fendi",
    value: "Fendi",
  },
  {
    label: "F&F",
    value: "F&F",
  },
  {
    label: "Figge",
    value: "Figge",
  },
  {
    label: "Figs",
    value: "Figs",
  },
  {
    label: "Fila",
    value: "Fila",
  },
  {
    label: "Filou & Friends",
    value: "Filou & Friends",
  },
  {
    label: "Finding Foxtale",
    value: "Finding Foxtale",
  },
  {
    label: "Finn + Emma",
    value: "Finn + Emma",
  },
  {
    label: "Fin & Vince",
    value: "Fin & Vince",
  },
  {
    label: "First Impressions",
    value: "First Impressions",
  },
  {
    label: "Fisher Price",
    value: "Fisher Price",
  },
  {
    label: "Five Dancewear",
    value: "Five Dancewear",
  },
  {
    label: "Fjallraven",
    value: "Fjallraven",
  },
  {
    label: "Fletch",
    value: "Fletch",
  },
  {
    label: "Floatimini",
    value: "Floatimini",
  },
  {
    label: "Flora and Henri",
    value: "Flora and Henri",
  },
  {
    label: "Florence Eiseman",
    value: "Florence Eiseman",
  },
  {
    label: "Florsheim",
    value: "Florsheim",
  },
  {
    label: "Fluff",
    value: "Fluff",
  },
  {
    label: "Fly London",
    value: "Fly London",
  },
  {
    label: "Footmates",
    value: "Footmates",
  },
  {
    label: "Ford and Wyatt",
    value: "Ford and Wyatt",
  },
  {
    label: "Fore!! Axel and Hudson",
    value: "Fore!! Axel and Hudson",
  },
  {
    label: "Forever 21",
    value: "Forever 21",
  },
  {
    label: "Fossil",
    value: "Fossil",
  },
  {
    label: "Fox & Horn",
    value: "Fox & Horn",
  },
  {
    label: "Foxy's Leotards",
    value: "Foxy's Leotards",
  },
  {
    label: "Frame",
    value: "Frame",
  },
  {
    label: "Frankie & Sue",
    value: "Frankie & Sue",
  },
  {
    label: "Freebird by Steven",
    value: "Freebird by Steven",
  },
  {
    label: "Free Birdees",
    value: "Free Birdees",
  },
  {
    label: "Free Country",
    value: "Free Country",
  },
  {
    label: "Freed of London",
    value: "Freed of London",
  },
  {
    label: "Freedom Moses",
    value: "Freedom Moses",
  },
  {
    label: "Free People",
    value: "Free People",
  },
  {
    label: "Free Planet",
    value: "Free Planet",
  },
  {
    label: "French Connection",
    value: "French Connection",
  },
  {
    label: "French Toast",
    value: "French Toast",
  },
  {
    label: "Freshly Hatched Threads",
    value: "Freshly Hatched Threads",
  },
  {
    label: "Freshly Picked",
    value: "Freshly Picked",
  },
  {
    label: "Fresh Produce",
    value: "Fresh Produce",
  },
  {
    label: "From Zion",
    value: "From Zion",
  },
  {
    label: "Frugi",
    value: "Frugi",
  },
  {
    label: "Frye",
    value: "Frye",
  },
  {
    label: "Funko",
    value: "Funko",
  },
  {
    label: "FurReal",
    value: "FurReal",
  },
  {
    label: "FuzziBunz",
    value: "FuzziBunz",
  },
  {
    label: "Gap",
    value: "Gap",
  },
  {
    label: "Garanimals",
    value: "Garanimals",
  },
  {
    label: "Garb",
    value: "Garb",
  },
  {
    label: "Garcon",
    value: "Garcon",
  },
  {
    label: "Gardner and the Gang",
    value: "Gardner and the Gang",
  },
  {
    label: "Garnet Hill",
    value: "Garnet Hill",
  },
  {
    label: "Gathre",
    value: "Gathre",
  },
  {
    label: "Gdiapers",
    value: "Gdiapers",
  },
  {
    label: "Geggamoja",
    value: "Geggamoja",
  },
  {
    label: "Gemma + Filo",
    value: "Gemma + Filo",
  },
  {
    label: "Gentle Fawn",
    value: "Gentle Fawn",
  },
  {
    label: "Gentle Souls",
    value: "Gentle Souls",
  },
  {
    label: "Genuine Kids",
    value: "Genuine Kids",
  },
  {
    label: "Geo Fox Apparel",
    value: "Geo Fox Apparel",
  },
  {
    label: "George Hats",
    value: "George Hats",
  },
  {
    label: "Geox",
    value: "Geox",
  },
  {
    label: "Gerber",
    value: "Gerber",
  },
  {
    label: "Gia-Mia Dancewear",
    value: "Gia-Mia Dancewear",
  },
  {
    label: "Giggle Moon",
    value: "Giggle Moon",
  },
  {
    label: "Gigi and Max",
    value: "Gigi and Max",
  },
  {
    label: "Girl August",
    value: "Girl August",
  },
  {
    label: "Girlhood",
    value: "Girlhood",
  },
  {
    label: "GK",
    value: "GK",
  },
  {
    label: "G.Nancy",
    value: "G.Nancy",
  },
  {
    label: "Goat-Milk",
    value: "Goat-Milk",
  },
  {
    label: "Go Gently Nation",
    value: "Go Gently Nation",
  },
  {
    label: "Golden Goose",
    value: "Golden Goose",
  },
  {
    label: "Goldsign",
    value: "Goldsign",
  },
  {
    label: "Goldybelle",
    value: "Goldybelle",
  },
  {
    label: "Good Lad",
    value: "Good Lad",
  },
  {
    label: "Goosebumps Clothing",
    value: "Goosebumps Clothing",
  },
  {
    label: "Goumi",
    value: "Goumi",
  },
  {
    label: "Gracious May",
    value: "Gracious May",
  },
  {
    label: "Grapat",
    value: "Grapat",
  },
  {
    label: "Gray Label",
    value: "Gray Label",
  },
  {
    label: "Grayson Mini",
    value: "Grayson Mini",
  },
  {
    label: "Great Pretenders",
    value: "Great Pretenders",
  },
  {
    label: "Grech & Co.",
    value: "Grech & Co.",
  },
  {
    label: "Greendog",
    value: "Greendog",
  },
  {
    label: "Green Sprouts",
    value: "Green Sprouts",
  },
  {
    label: "Green Toys",
    value: "Green Toys",
  },
  {
    label: "Grendene",
    value: "Grendene",
  },
  {
    label: "Grimm's",
    value: "Grimm's",
  },
  {
    label: "Grishko",
    value: "Grishko",
  },
  {
    label: "Grom Squad",
    value: "Grom Squad",
  },
  {
    label: "GroVia",
    value: "GroVia",
  },
  {
    label: "Gucci",
    value: "Gucci",
  },
  {
    label: "Guess",
    value: "Guess",
  },
  {
    label: "Gunamuna",
    value: "Gunamuna",
  },
  {
    label: "Gunner + Lux",
    value: "Gunner + Lux",
  },
  {
    label: "Gus + Steel",
    value: "Gus + Steel",
  },
  {
    label: "Gymboree",
    value: "Gymboree",
  },
  {
    label: "Gymshark",
    value: "Gymshark",
  },
  {
    label: "HABA",
    value: "HABA",
  },
  {
    label: "Habitual",
    value: "Habitual",
  },
  {
    label: "Halabaloo",
    value: "Halabaloo",
  },
  {
    label: "Hallmark Baby",
    value: "Hallmark Baby",
  },
  {
    label: "Halo",
    value: "Halo",
  },
  {
    label: "Halogen",
    value: "Halogen",
  },
  {
    label: "Halston",
    value: "Halston",
  },
  {
    label: "Handmade",
    value: "Handmade",
  },
  {
    label: "Hanna Andersson",
    value: "Hanna Andersson",
  },
  {
    label: "Hannah Banana",
    value: "Hannah Banana",
  },
  {
    label: "Hannah Kate",
    value: "Hannah Kate",
  },
  {
    label: "Hape",
    value: "Hape",
  },
  {
    label: "Happ",
    value: "Happ",
  },
  {
    label: "Harajuku Mini",
    value: "Harajuku Mini",
  },
  {
    label: "Hari Mari",
    value: "Hari Mari",
  },
  {
    label: "Harley Davidson",
    value: "Harley Davidson",
  },
  {
    label: "Harlow Jade",
    value: "Harlow Jade",
  },
  {
    label: "Harper Canyon",
    value: "Harper Canyon",
  },
  {
    label: "HarperIman Dolls",
    value: "HarperIman Dolls",
  },
  {
    label: "Hart + Land",
    value: "Hart + Land",
  },
  {
    label: "Hartstrings",
    value: "Hartstrings",
  },
  {
    label: "Hasbro",
    value: "Hasbro",
  },
  {
    label: "HATCH",
    value: "HATCH",
  },
  {
    label: "Hatch For Kids",
    value: "Hatch For Kids",
  },
  {
    label: "Hatchimals",
    value: "Hatchimals",
  },
  {
    label: "Hatley",
    value: "Hatley",
  },
  {
    label: "Haus Of Jr",
    value: "Haus Of Jr",
  },
  {
    label: "Havaianas",
    value: "Havaianas",
  },
  {
    label: "Haven Kids",
    value: "Haven Kids",
  },
  {
    label: "Hayden",
    value: "Hayden",
  },
  {
    label: "Hazel Village",
    value: "Hazel Village",
  },
  {
    label: "Hazi Kids",
    value: "Hazi Kids",
  },
  {
    label: "HEAD",
    value: "HEAD",
  },
  {
    label: "Healthtex",
    value: "Healthtex",
  },
  {
    label: "Hearth & Hand",
    value: "Hearth & Hand",
  },
  {
    label: "Hello Apparel",
    value: "Hello Apparel",
  },
  {
    label: "Hello Ellie",
    value: "Hello Ellie",
  },
  {
    label: "Hello Kitty",
    value: "Hello Kitty",
  },
  {
    label: "Helly Hansen",
    value: "Helly Hansen",
  },
  {
    label: "Hemlock Hat Co.",
    value: "Hemlock Hat Co.",
  },
  {
    label: "Henry + Claire",
    value: "Henry + Claire",
  },
  {
    label: "Herschel",
    value: "Herschel",
  },
  {
    label: "Hestra",
    value: "Hestra",
  },
  {
    label: "Hey Dude",
    value: "Hey Dude",
  },
  {
    label: "HIHO Batik",
    value: "HIHO Batik",
  },
  {
    label: "H.I.P.",
    value: "H.I.P.",
  },
  {
    label: "Hip Baby Wrap",
    value: "Hip Baby Wrap",
  },
  {
    label: "H&M",
    value: "H&M",
  },
  {
    label: "Hobo",
    value: "Hobo",
  },
  {
    label: "Hoka",
    value: "Hoka",
  },
  {
    label: "Holley + Sage",
    value: "Holley + Sage",
  },
  {
    label: "Hollister",
    value: "Hollister",
  },
  {
    label: "Holztiger",
    value: "Holztiger",
  },
  {
    label: "Honest Company",
    value: "Honest Company",
  },
  {
    label: "Honey and Hide",
    value: "Honey and Hide",
  },
  {
    label: "Hoonana",
    value: "Hoonana",
  },
  {
    label: "Hope & Henry",
    value: "Hope & Henry",
  },
  {
    label: "Hot Chocolate Design",
    value: "Hot Chocolate Design",
  },
  {
    label: "Hot Sling",
    value: "Hot Sling",
  },
  {
    label: "Hot Wheels",
    value: "Hot Wheels",
  },
  {
    label: "House Of Paloma",
    value: "House Of Paloma",
  },
  {
    label: "Hubble Connected",
    value: "Hubble Connected",
  },
  {
    label: "Hubble + Duke",
    value: "Hubble + Duke",
  },
  {
    label: "Hudson",
    value: "Hudson",
  },
  {
    label: "Huggalugs",
    value: "Huggalugs",
  },
  {
    label: "Hugo Loves Tiki",
    value: "Hugo Loves Tiki",
  },
  {
    label: "Hum Stitchery",
    value: "Hum Stitchery",
  },
  {
    label: "Hundred Pieces",
    value: "Hundred Pieces",
  },
  {
    label: "Hunter",
    value: "Hunter",
  },
  {
    label: "Hurley",
    value: "Hurley",
  },
  {
    label: "Hush Puppies",
    value: "Hush Puppies",
  },
  {
    label: "Huxbaby",
    value: "Huxbaby",
  },
  {
    label: "Hvid",
    value: "Hvid",
  },
  {
    label: "Icebreaker",
    value: "Icebreaker",
  },
  {
    label: "Ice Cream Castles",
    value: "Ice Cream Castles",
  },
  {
    label: "Icky",
    value: "Icky",
  },
  {
    label: "Ideology",
    value: "Ideology",
  },
  {
    label: "I Dig Denim",
    value: "I Dig Denim",
  },
  {
    label: "Igi Natur",
    value: "Igi Natur",
  },
  {
    label: "Igor",
    value: "Igor",
  },
  {
    label: "IKKS",
    value: "IKKS",
  },
  {
    label: "Il Gufo",
    value: "Il Gufo",
  },
  {
    label: "Illoura the Label",
    value: "Illoura the Label",
  },
  {
    label: "I Love Gorgeous",
    value: "I Love Gorgeous",
  },
  {
    label: "iloveplum",
    value: "iloveplum",
  },
  {
    label: "Imaginext",
    value: "Imaginext",
  },
  {
    label: "Imoga",
    value: "Imoga",
  },
  {
    label: "Imps & Elfs",
    value: "Imps & Elfs",
  },
  {
    label: "INC International Concepts",
    value: "INC International Concepts",
  },
  {
    label: "InCity",
    value: "InCity",
  },
  {
    label: "IndieBird",
    value: "IndieBird",
  },
  {
    label: "Indikidual",
    value: "Indikidual",
  },
  {
    label: "Indy and Pippa",
    value: "Indy and Pippa",
  },
  {
    label: "Infantino",
    value: "Infantino",
  },
  {
    label: "Inglesina",
    value: "Inglesina",
  },
  {
    label: "Ingrid & Isabel",
    value: "Ingrid & Isabel",
  },
  {
    label: "Inklings",
    value: "Inklings",
  },
  {
    label: "Intermezzo Dancewear",
    value: "Intermezzo Dancewear",
  },
  {
    label: "iplay",
    value: "iplay",
  },
  {
    label: "Irideon",
    value: "Irideon",
  },
  {
    label: "Isaac Mizrahi",
    value: "Isaac Mizrahi",
  },
  {
    label: "Isabel Garreton",
    value: "Isabel Garreton",
  },
  {
    label: "Isabella Oliver",
    value: "Isabella Oliver",
  },
  {
    label: "Isabel Maternity",
    value: "Isabel Maternity",
  },
  {
    label: "Isobella and Chloe",
    value: "Isobella and Chloe",
  },
  {
    label: "Itzy Ritzy",
    value: "Itzy Ritzy",
  },
  {
    label: "Iver and Isla",
    value: "Iver and Isla",
  },
  {
    label: "Ivivva",
    value: "Ivivva",
  },
  {
    label: "Ivy City",
    value: "Ivy City",
  },
  {
    label: "Izod",
    value: "Izod",
  },
  {
    label: "Izzy & Ferd",
    value: "Izzy & Ferd",
  },
  {
    label: "Jacadi",
    value: "Jacadi",
  },
  {
    label: "Jack & Lily",
    value: "Jack & Lily",
  },
  {
    label: "Jack Rogers",
    value: "Jack Rogers",
  },
  {
    label: "Jack & Winn",
    value: "Jack & Winn",
  },
  {
    label: "Jak & Peppar",
    value: "Jak & Peppar",
  },
  {
    label: "Jambu",
    value: "Jambu",
  },
  {
    label: "James Jeans",
    value: "James Jeans",
  },
  {
    label: "James & Lottie",
    value: "James & Lottie",
  },
  {
    label: "James Perse",
    value: "James Perse",
  },
  {
    label: "James Vincent Design Co.",
    value: "James Vincent Design Co.",
  },
  {
    label: "Jamie Kay",
    value: "Jamie Kay",
  },
  {
    label: "Jane + Jo",
    value: "Jane + Jo",
  },
  {
    label: "Janie and Jack",
    value: "Janie and Jack",
  },
  {
    label: "Janod",
    value: "Janod",
  },
  {
    label: "Jansport",
    value: "Jansport",
  },
  {
    label: "Jax + Lennon",
    value: "Jax + Lennon",
  },
  {
    label: "J Brand",
    value: "J Brand",
  },
  {
    label: "J. Crew",
    value: "J. Crew",
  },
  {
    label: "J.Crew Baby",
    value: "J.Crew Baby",
  },
  {
    label: "Jean Bourget",
    value: "Jean Bourget",
  },
  {
    label: "Jean + June",
    value: "Jean + June",
  },
  {
    label: "Jellycat",
    value: "Jellycat",
  },
  {
    label: "Jellymallow",
    value: "Jellymallow",
  },
  {
    label: "Jelly the Pug",
    value: "Jelly the Pug",
  },
  {
    label: "Jenna & Jessie",
    value: "Jenna & Jessie",
  },
  {
    label: "Jenni Kayne",
    value: "Jenni Kayne",
  },
  {
    label: "Jessica Simpson",
    value: "Jessica Simpson",
  },
  {
    label: "Jeune",
    value: "Jeune",
  },
  {
    label: "Jill Yoga",
    value: "Jill Yoga",
  },
  {
    label: "Jimmy Choo",
    value: "Jimmy Choo",
  },
  {
    label: "JJ Cole",
    value: "JJ Cole",
  },
  {
    label: "J. Jill",
    value: "J. Jill",
  },
  {
    label: "Joah Love",
    value: "Joah Love",
  },
  {
    label: "Joe Fresh",
    value: "Joe Fresh",
  },
  {
    label: "Joe's Jeans",
    value: "Joe's Jeans",
  },
  {
    label: "John Deere",
    value: "John Deere",
  },
  {
    label: "John Lewis",
    value: "John Lewis",
  },
  {
    label: "Johnnie B",
    value: "Johnnie B",
  },
  {
    label: "johnnie-O",
    value: "johnnie-O",
  },
  {
    label: "Johnny Banana",
    value: "Johnny Banana",
  },
  {
    label: "Joie",
    value: "Joie",
  },
  {
    label: "Jo+Jax",
    value: "Jo+Jax",
  },
  {
    label: "JoJo Maman Bebe",
    value: "JoJo Maman Bebe",
  },
  {
    label: "Jolly Jumper",
    value: "Jolly Jumper",
  },
  {
    label: "Jona Michelle",
    value: "Jona Michelle",
  },
  {
    label: "Jordache",
    value: "Jordache",
  },
  {
    label: "Jordan",
    value: "Jordan",
  },
  {
    label: "Jordan Craig",
    value: "Jordan Craig",
  },
  {
    label: "Jottum",
    value: "Jottum",
  },
  {
    label: "Joules",
    value: "Joules",
  },
  {
    label: "Joyaltee",
    value: "Joyaltee",
  },
  {
    label: "Joyfolie",
    value: "Joyfolie",
  },
  {
    label: "Jr. Cooper",
    value: "Jr. Cooper",
  },
  {
    label: "Judith March",
    value: "Judith March",
  },
  {
    label: "Juicy Couture",
    value: "Juicy Couture",
  },
  {
    label: "JuJuBe",
    value: "JuJuBe",
  },
  {
    label: "Jumping Beans",
    value: "Jumping Beans",
  },
  {
    label: "June + Grey",
    value: "June + Grey",
  },
  {
    label: "June & January",
    value: "June & January",
  },
  {
    label: "June Loop",
    value: "June Loop",
  },
  {
    label: "June Park",
    value: "June Park",
  },
  {
    label: "Junie Grey",
    value: "Junie Grey",
  },
  {
    label: "Junk Food",
    value: "Junk Food",
  },
  {
    label: "Justice",
    value: "Justice",
  },
  {
    label: "Justin",
    value: "Justin",
  },
  {
    label: "Kalencom",
    value: "Kalencom",
  },
  {
    label: "Kalinka",
    value: "Kalinka",
  },
  {
    label: "Kalin Marie",
    value: "Kalin Marie",
  },
  {
    label: "Kamik",
    value: "Kamik",
  },
  {
    label: "Kandi Kouture",
    value: "Kandi Kouture",
  },
  {
    label: "Kanga Care",
    value: "Kanga Care",
  },
  {
    label: "KAOS Recycled",
    value: "KAOS Recycled",
  },
  {
    label: "Kapital K",
    value: "Kapital K",
  },
  {
    label: "Karen Millen",
    value: "Karen Millen",
  },
  {
    label: "Karl Lagerfeld",
    value: "Karl Lagerfeld",
  },
  {
    label: "Karma Baby",
    value: "Karma Baby",
  },
  {
    label: "Kate Quinn Organics",
    value: "Kate Quinn Organics",
  },
  {
    label: "Kate Spade",
    value: "Kate Spade",
  },
  {
    label: "Kavio",
    value: "Kavio",
  },
  {
    label: "K-Bee Leotards",
    value: "K-Bee Leotards",
  },
  {
    label: "Keds",
    value: "Keds",
  },
  {
    label: "Keen",
    value: "Keen",
  },
  {
    label: "Kelly's Kids",
    value: "Kelly's Kids",
  },
  {
    label: "Kendra Scott",
    value: "Kendra Scott",
  },
  {
    label: "Kenneth Cole",
    value: "Kenneth Cole",
  },
  {
    label: "Kensie",
    value: "Kensie",
  },
  {
    label: "Kerrits",
    value: "Kerrits",
  },
  {
    label: "Ketiketa",
    value: "Ketiketa",
  },
  {
    label: "Khombu",
    value: "Khombu",
  },
  {
    label: "Kickee Pants",
    value: "Kickee Pants",
  },
  {
    label: "Kicker's",
    value: "Kicker's",
  },
  {
    label: "Kid + Kind",
    value: "Kid + Kind",
  },
  {
    label: "KidKraft",
    value: "KidKraft",
  },
  {
    label: "Kidpik",
    value: "Kidpik",
  },
  {
    label: "Kids Headquarters",
    value: "Kids Headquarters",
  },
  {
    label: "Kidwild",
    value: "Kidwild",
  },
  {
    label: "Kiki + Lulu",
    value: "Kiki + Lulu",
  },
  {
    label: "KinBE",
    value: "KinBE",
  },
  {
    label: "Kind Kids Club",
    value: "Kind Kids Club",
  },
  {
    label: "Kindly",
    value: "Kindly",
  },
  {
    label: "Kindred Bravely",
    value: "Kindred Bravely",
  },
  {
    label: "Kindred Oak",
    value: "Kindred Oak",
  },
  {
    label: "Kingsley",
    value: "Kingsley",
  },
  {
    label: "Kipling",
    value: "Kipling",
  },
  {
    label: "Kipp",
    value: "Kipp",
  },
  {
    label: "Kira Kids",
    value: "Kira Kids",
  },
  {
    label: "Kirkland",
    value: "Kirkland",
  },
  {
    label: "Kissaluvs",
    value: "Kissaluvs",
  },
  {
    label: "Kissy Kissy",
    value: "Kissy Kissy",
  },
  {
    label: "Kite",
    value: "Kite",
  },
  {
    label: "Kitestrings",
    value: "Kitestrings",
  },
  {
    label: "KiwiCo",
    value: "KiwiCo",
  },
  {
    label: "Kiwi Industries",
    value: "Kiwi Industries",
  },
  {
    label: "K'Nex",
    value: "K'Nex",
  },
  {
    label: "Knotted Fern",
    value: "Knotted Fern",
  },
  {
    label: "Knuckleheads Clothing",
    value: "Knuckleheads Clothing",
  },
  {
    label: "Koala Baby",
    value: "Koala Baby",
  },
  {
    label: "Kombi",
    value: "Kombi",
  },
  {
    label: "Konges Slojd",
    value: "Konges Slojd",
  },
  {
    label: "Kork-Ease",
    value: "Kork-Ease",
  },
  {
    label: "Kortni Jeane",
    value: "Kortni Jeane",
  },
  {
    label: "Kozi & Co",
    value: "Kozi & Co",
  },
  {
    label: "KPea",
    value: "KPea",
  },
  {
    label: "K-Swiss",
    value: "K-Swiss",
  },
  {
    label: "Kukukid",
    value: "Kukukid",
  },
  {
    label: "Kula",
    value: "Kula",
  },
  {
    label: "Kuling",
    value: "Kuling",
  },
  {
    label: "Kut from the Kloth",
    value: "Kut from the Kloth",
  },
  {
    label: "K-Way",
    value: "K-Way",
  },
  {
    label: "Kyte Baby",
    value: "Kyte Baby",
  },
  {
    label: "Lacey Lane",
    value: "Lacey Lane",
  },
  {
    label: "La Coqueta",
    value: "La Coqueta",
  },
  {
    label: "Lacoste",
    value: "Lacoste",
  },
  {
    label: "LA Hearts",
    value: "LA Hearts",
  },
  {
    label: "Lake & Ramona",
    value: "Lake & Ramona",
  },
  {
    label: "Lakeshore",
    value: "Lakeshore",
  },
  {
    label: "Lali Kids",
    value: "Lali Kids",
  },
  {
    label: "Lamaze",
    value: "Lamaze",
  },
  {
    label: "La Miniatura",
    value: "La Miniatura",
  },
  {
    label: "L'Amour",
    value: "L'Amour",
  },
  {
    label: "Land & Fin",
    value: "Land & Fin",
  },
  {
    label: "Land of Nod",
    value: "Land of Nod",
  },
  {
    label: "Lands End",
    value: "Lands End",
  },
  {
    label: "Lane Bryant",
    value: "Lane Bryant",
  },
  {
    label: "Lansinoh",
    value: "Lansinoh",
  },
  {
    label: "Lark Adventurewear",
    value: "Lark Adventurewear",
  },
  {
    label: "Lately Lily",
    value: "Lately Lily",
  },
  {
    label: "Laundry",
    value: "Laundry",
  },
  {
    label: "Laura Ashley",
    value: "Laura Ashley",
  },
  {
    label: "Lauren Moshi",
    value: "Lauren Moshi",
  },
  {
    label: "Lazy One",
    value: "Lazy One",
  },
  {
    label: "Leapfrog",
    value: "Leapfrog",
  },
  {
    label: "Learning Resources",
    value: "Learning Resources",
  },
  {
    label: "Lee",
    value: "Lee",
  },
  {
    label: "Left On Friday",
    value: "Left On Friday",
  },
  {
    label: "Lego",
    value: "Lego",
  },
  {
    label: "Legoe",
    value: "Legoe",
  },
  {
    label: "Leith",
    value: "Leith",
  },
  {
    label: "Lelli Kelly",
    value: "Lelli Kelly",
  },
  {
    label: "Lemon Loves Lime",
    value: "Lemon Loves Lime",
  },
  {
    label: "Lennon + Wolfe",
    value: "Lennon + Wolfe",
  },
  {
    label: "LENOX JAMES",
    value: "LENOX JAMES",
  },
  {
    label: "Leota",
    value: "Leota",
  },
  {
    label: "Leotard Boutique",
    value: "Leotard Boutique",
  },
  {
    label: "Le Petit Elle",
    value: "Le Petit Elle",
  },
  {
    label: "Le Petit Germain",
    value: "Le Petit Germain",
  },
  {
    label: "Le Petit Organic",
    value: "Le Petit Organic",
  },
  {
    label: "Les Gamins",
    value: "Les Gamins",
  },
  {
    label: "Le top",
    value: "Le top",
  },
  {
    label: "Leveret",
    value: "Leveret",
  },
  {
    label: "Levi's",
    value: "Levi's",
  },
  {
    label: "Lewis",
    value: "Lewis",
  },
  {
    label: "Liboosha",
    value: "Liboosha",
  },
  {
    label: "Liewood",
    value: "Liewood",
  },
  {
    label: "Lifefactory",
    value: "Lifefactory",
  },
  {
    label: "Life is Good",
    value: "Life is Good",
  },
  {
    label: "LIHO",
    value: "LIHO",
  },
  {
    label: "Liilu",
    value: "Liilu",
  },
  {
    label: "Lil Cactus",
    value: "Lil Cactus",
  },
  {
    label: "Lili Gaufrette",
    value: "Lili Gaufrette",
  },
  {
    label: "Lil Joey",
    value: "Lil Joey",
  },
  {
    label: "Lillebaby",
    value: "Lillebaby",
  },
  {
    label: "Lil Legs",
    value: "Lil Legs",
  },
  {
    label: "Lilly Pulitzer",
    value: "Lilly Pulitzer",
  },
  {
    label: "Lil XO Kings",
    value: "Lil XO Kings",
  },
  {
    label: "Lily Bleu",
    value: "Lily Bleu",
  },
  {
    label: "Lily Jade",
    value: "Lily Jade",
  },
  {
    label: "Lily & Londyn's",
    value: "Lily & Londyn's",
  },
  {
    label: "Lindex",
    value: "Lindex",
  },
  {
    label: "Liney Lu",
    value: "Liney Lu",
  },
  {
    label: "Little Adi + Co.",
    value: "Little Adi + Co.",
  },
  {
    label: "Little Adventures",
    value: "Little Adventures",
  },
  {
    label: "Little Bipsy",
    value: "Little Bipsy",
  },
  {
    label: "Little Bird by Jools Oliver",
    value: "Little Bird by Jools Oliver",
  },
  {
    label: "Little Bird Clothing Co.",
    value: "Little Bird Clothing Co.",
  },
  {
    label: "Little Boogaweezin",
    value: "Little Boogaweezin",
  },
  {
    label: "Little Bum Bums",
    value: "Little Bum Bums",
  },
  {
    label: "Little Co. by Lauren Conrad",
    value: "Little Co. by Lauren Conrad",
  },
  {
    label: "Little Cotton Clothing",
    value: "Little Cotton Clothing",
  },
  {
    label: "Little Cottonwood",
    value: "Little Cottonwood",
  },
  {
    label: "Little Creative Factory",
    value: "Little Creative Factory",
  },
  {
    label: "Little English",
    value: "Little English",
  },
  {
    label: "Little Foxtrot",
    value: "Little Foxtrot",
  },
  {
    label: "Little Giraffe",
    value: "Little Giraffe",
  },
  {
    label: "Little Golden Books",
    value: "Little Golden Books",
  },
  {
    label: "Little Goodall",
    value: "Little Goodall",
  },
  {
    label: "Little Green Radicals",
    value: "Little Green Radicals",
  },
  {
    label: "Little Gypsy Finery",
    value: "Little Gypsy Finery",
  },
  {
    label: "Little Handprint",
    value: "Little Handprint",
  },
  {
    label: "Little Hedonist",
    value: "Little Hedonist",
  },
  {
    label: "Little Hip Squeaks",
    value: "Little Hip Squeaks",
  },
  {
    label: "Little Hooligans",
    value: "Little Hooligans",
  },
  {
    label: "Little Horn",
    value: "Little Horn",
  },
  {
    label: "Little Lass",
    value: "Little Lass",
  },
  {
    label: "Little Light Feet",
    value: "Little Light Feet",
  },
  {
    label: "Little & Lively",
    value: "Little & Lively",
  },
  {
    label: "Little Man Happy",
    value: "Little Man Happy",
  },
  {
    label: "Little Marc Jacobs",
    value: "Little Marc Jacobs",
  },
  {
    label: "Little Me",
    value: "Little Me",
  },
  {
    label: "Little Minis",
    value: "Little Minis",
  },
  {
    label: "Little Nugget Knits",
    value: "Little Nugget Knits",
  },
  {
    label: "Little One Shop",
    value: "Little One Shop",
  },
  {
    label: "Little People Rocx",
    value: "Little People Rocx",
  },
  {
    label: "Little Poppy Co",
    value: "Little Poppy Co",
  },
  {
    label: "Little Road Co",
    value: "Little Road Co",
  },
  {
    label: "Little Sleepies",
    value: "Little Sleepies",
  },
  {
    label: "Little Stocking Co.",
    value: "Little Stocking Co.",
  },
  {
    label: "Littlest Pet Shop",
    value: "Littlest Pet Shop",
  },
  {
    label: "Little Tikes",
    value: "Little Tikes",
  },
  {
    label: "Little Unicorn",
    value: "Little Unicorn",
  },
  {
    label: "Little Urban Apparel",
    value: "Little Urban Apparel",
  },
  {
    label: "Little Wedgies",
    value: "Little Wedgies",
  },
  {
    label: "Little Willow Tree",
    value: "Little Willow Tree",
  },
  {
    label: "Livie & Luca",
    value: "Livie & Luca",
  },
  {
    label: "Liz Lange",
    value: "Liz Lange",
  },
  {
    label: "L.L. Bean",
    value: "L.L. Bean",
  },
  {
    label: "Llum",
    value: "Llum",
  },
  {
    label: "LOFT",
    value: "LOFT",
  },
  {
    label: "Lola + The Boys",
    value: "Lola + The Boys",
  },
  {
    label: "Lolly Wolly Doodle",
    value: "Lolly Wolly Doodle",
  },
  {
    label: "LOL Surprise Dolls",
    value: "LOL Surprise Dolls",
  },
  {
    label: "London Fog",
    value: "London Fog",
  },
  {
    label: "Longchamp",
    value: "Longchamp",
  },
  {
    label: "Lost + Wander",
    value: "Lost + Wander",
  },
  {
    label: "Lottie Dolls",
    value: "Lottie Dolls",
  },
  {
    label: "Loud Apparel",
    value: "Loud Apparel",
  },
  {
    label: "Lou & Grey",
    value: "Lou & Grey",
  },
  {
    label: "Louise Misha",
    value: "Louise Misha",
  },
  {
    label: "Louis Louise",
    value: "Louis Louise",
  },
  {
    label: "Louis Vuitton",
    value: "Louis Vuitton",
  },
  {
    label: "Lou Lou & Company",
    value: "Lou Lou & Company",
  },
  {
    label: "Loulou Lollipop",
    value: "Loulou Lollipop",
  },
  {
    label: "Loungefly",
    value: "Loungefly",
  },
  {
    label: "Lourdes",
    value: "Lourdes",
  },
  {
    label: "L'ovedbaby",
    value: "L'ovedbaby",
  },
  {
    label: "Loved by Hannah and Eli",
    value: "Loved by Hannah and Eli",
  },
  {
    label: "Love, Fire",
    value: "Love, Fire",
  },
  {
    label: "Love & Grow Clothing Co",
    value: "Love & Grow Clothing Co",
  },
  {
    label: "LoveShackFancy",
    value: "LoveShackFancy",
  },
  {
    label: "Loves Me Not",
    value: "Loves Me Not",
  },
  {
    label: "Love To Dream",
    value: "Love To Dream",
  },
  {
    label: "Lovevery",
    value: "Lovevery",
  },
  {
    label: "Love Your Melon",
    value: "Love Your Melon",
  },
  {
    label: "Luca The Label",
    value: "Luca The Label",
  },
  {
    label: "Lucky Brand",
    value: "Lucky Brand",
  },
  {
    label: "Lucy",
    value: "Lucy",
  },
  {
    label: "Lucy & Leo",
    value: "Lucy & Leo",
  },
  {
    label: "Lucy Lue Organics",
    value: "Lucy Lue Organics",
  },
  {
    label: "LuLaRoe",
    value: "LuLaRoe",
  },
  {
    label: "Luli & Me",
    value: "Luli & Me",
  },
  {
    label: "Lullaby Lane",
    value: "Lullaby Lane",
  },
  {
    label: "Lullaby Set",
    value: "Lullaby Set",
  },
  {
    label: "Lulli Dancewear",
    value: "Lulli Dancewear",
  },
  {
    label: "Lululemon",
    value: "Lululemon",
  },
  {
    label: "Lulu & Ren",
    value: "Lulu & Ren",
  },
  {
    label: "Lulu + Roo",
    value: "Lulu + Roo",
  },
  {
    label: "Lulus",
    value: "Lulus",
  },
  {
    label: "Luna Luna",
    value: "Luna Luna",
  },
  {
    label: "Luna Luna Copenhagen",
    value: "Luna Luna Copenhagen",
  },
  {
    label: "Lunya",
    value: "Lunya",
  },
  {
    label: "Lu & Ro",
    value: "Lu & Ro",
  },
  {
    label: "Lush Clothing",
    value: "Lush Clothing",
  },
  {
    label: "Maaji",
    value: "Maaji",
  },
  {
    label: "Maa Shoes",
    value: "Maa Shoes",
  },
  {
    label: "Mabel + Honey",
    value: "Mabel + Honey",
  },
  {
    label: "Mabo",
    value: "Mabo",
  },
  {
    label: "Maddy James",
    value: "Maddy James",
  },
  {
    label: "Madewell",
    value: "Madewell",
  },
  {
    label: "Madeworn",
    value: "Madeworn",
  },
  {
    label: "Madison Grace",
    value: "Madison Grace",
  },
  {
    label: "Mad Sky",
    value: "Mad Sky",
  },
  {
    label: "Mae and Mum",
    value: "Mae and Mum",
  },
  {
    label: "Maed for Mini",
    value: "Maed for Mini",
  },
  {
    label: "Maggie & Zoe",
    value: "Maggie & Zoe",
  },
  {
    label: "Magna-Tiles",
    value: "Magna-Tiles",
  },
  {
    label: "Magnetic Me",
    value: "Magnetic Me",
  },
  {
    label: "Magnolia Baby",
    value: "Magnolia Baby",
  },
  {
    label: "Mag + Pie",
    value: "Mag + Pie",
  },
  {
    label: "Magpie & Mabel",
    value: "Magpie & Mabel",
  },
  {
    label: "Maileg",
    value: "Maileg",
  },
  {
    label: "Mainio",
    value: "Mainio",
  },
  {
    label: "Maisie Jayne",
    value: "Maisie Jayne",
  },
  {
    label: "Maison Me",
    value: "Maison Me",
  },
  {
    label: "Maje",
    value: "Maje",
  },
  {
    label: "Makemake Organics",
    value: "Makemake Organics",
  },
  {
    label: "Makena Lane",
    value: "Makena Lane",
  },
  {
    label: "Makie",
    value: "Makie",
  },
  {
    label: "Malia + Kai",
    value: "Malia + Kai",
  },
  {
    label: "Mamalicious",
    value: "Mamalicious",
  },
  {
    label: "Mango",
    value: "Mango",
  },
  {
    label: "Manhattan Toy",
    value: "Manhattan Toy",
  },
  {
    label: "Manuela de Juan",
    value: "Manuela de Juan",
  },
  {
    label: "Marc Jacobs",
    value: "Marc Jacobs",
  },
  {
    label: "Maria Elena",
    value: "Maria Elena",
  },
  {
    label: "Marie Chantal",
    value: "Marie Chantal",
  },
  {
    label: "Marika",
    value: "Marika",
  },
  {
    label: "Marimekko",
    value: "Marimekko",
  },
  {
    label: "Marks & Spencer",
    value: "Marks & Spencer",
  },
  {
    label: "MarMar Copenhagen",
    value: "MarMar Copenhagen",
  },
  {
    label: "Marmot",
    value: "Marmot",
  },
  {
    label: "Marvel Comics",
    value: "Marvel Comics",
  },
  {
    label: "Masala Baby",
    value: "Masala Baby",
  },
  {
    label: "Mate the Label",
    value: "Mate the Label",
  },
  {
    label: "Matilda Jane",
    value: "Matilda Jane",
  },
  {
    label: "Matilda Knows",
    value: "Matilda Knows",
  },
  {
    label: "Mattel",
    value: "Mattel",
  },
  {
    label: "Matt & Nat",
    value: "Matt & Nat",
  },
  {
    label: "Maurices",
    value: "Maurices",
  },
  {
    label: "Mavi",
    value: "Mavi",
  },
  {
    label: "Maxi-Cosi",
    value: "Maxi-Cosi",
  },
  {
    label: "Max & Jake",
    value: "Max & Jake",
  },
  {
    label: "Maxomorra",
    value: "Maxomorra",
  },
  {
    label: "Max Studio",
    value: "Max Studio",
  },
  {
    label: "Maya Wrap",
    value: "Maya Wrap",
  },
  {
    label: "Mayoral",
    value: "Mayoral",
  },
  {
    label: "Mebi",
    value: "Mebi",
  },
  {
    label: "Mebie Baby",
    value: "Mebie Baby",
  },
  {
    label: "Medela",
    value: "Medela",
  },
  {
    label: "Mega Bloks",
    value: "Mega Bloks",
  },
  {
    label: "Me & Henry",
    value: "Me & Henry",
  },
  {
    label: "Mel by Melissa",
    value: "Mel by Melissa",
  },
  {
    label: "Melissa",
    value: "Melissa",
  },
  {
    label: "Melissa + Doug",
    value: "Melissa + Doug",
  },
  {
    label: "Melrose and Market",
    value: "Melrose and Market",
  },
  {
    label: "Me & Reekie",
    value: "Me & Reekie",
  },
  {
    label: "Meri Meri",
    value: "Meri Meri",
  },
  {
    label: "Merona",
    value: "Merona",
  },
  {
    label: "Merrell",
    value: "Merrell",
  },
  {
    label: "Me Too",
    value: "Me Too",
  },
  {
    label: "Mexx",
    value: "Mexx",
  },
  {
    label: "Mia Belle",
    value: "Mia Belle",
  },
  {
    label: "Mia Joy",
    value: "Mia Joy",
  },
  {
    label: "Mia Miel",
    value: "Mia Miel",
  },
  {
    label: "Michael Kors",
    value: "Michael Kors",
  },
  {
    label: "Michael Stars",
    value: "Michael Stars",
  },
  {
    label: "Midnight Magnolia",
    value: "Midnight Magnolia",
  },
  {
    label: "Miki Miette",
    value: "Miki Miette",
  },
  {
    label: "Mikoleon",
    value: "Mikoleon",
  },
  {
    label: "Mila & Rose",
    value: "Mila & Rose",
  },
  {
    label: "Miles Baby",
    value: "Miles Baby",
  },
  {
    label: "Miles + Milan",
    value: "Miles + Milan",
  },
  {
    label: "miles the label.",
    value: "miles the label.",
  },
  {
    label: "MilkBarn",
    value: "MilkBarn",
  },
  {
    label: "Millie Loves Lily",
    value: "Millie Loves Lily",
  },
  {
    label: "Millie + Roo",
    value: "Millie + Roo",
  },
  {
    label: "Millk",
    value: "Millk",
  },
  {
    label: "Milly",
    value: "Milly",
  },
  {
    label: "Mimi & Maggie",
    value: "Mimi & Maggie",
  },
  {
    label: "Miminoo",
    value: "Miminoo",
  },
  {
    label: "Mim Pi",
    value: "Mim Pi",
  },
  {
    label: "Mingo",
    value: "Mingo",
  },
  {
    label: "Mini A Ture",
    value: "Mini A Ture",
  },
  {
    label: "Mini Boden",
    value: "Mini Boden",
  },
  {
    label: "MiniBrook",
    value: "MiniBrook",
  },
  {
    label: "Miniclasix",
    value: "Miniclasix",
  },
  {
    label: "Mini Club",
    value: "Mini Club",
  },
  {
    label: "Mini Fine",
    value: "Mini Fine",
  },
  {
    label: "Minikane",
    value: "Minikane",
  },
  {
    label: "Mini Kula",
    value: "Mini Kula",
  },
  {
    label: "Miniland",
    value: "Miniland",
  },
  {
    label: "Mini Loop",
    value: "Mini Loop",
  },
  {
    label: "Minimalist Folk Co.",
    value: "Minimalist Folk Co.",
  },
  {
    label: "Mini & Maximus",
    value: "Mini & Maximus",
  },
  {
    label: "Mini + Meep",
    value: "Mini + Meep",
  },
  {
    label: "MiniMeis",
    value: "MiniMeis",
  },
  {
    label: "Mini Melissa",
    value: "Mini Melissa",
  },
  {
    label: "Mini Mioche",
    value: "Mini Mioche",
  },
  {
    label: "Mini Rodini",
    value: "Mini Rodini",
  },
  {
    label: "Mini Shatsu",
    value: "Mini Shatsu",
  },
  {
    label: "Minkpink",
    value: "Minkpink",
  },
  {
    label: "Minnetonka",
    value: "Minnetonka",
  },
  {
    label: "Minnow",
    value: "Minnow",
  },
  {
    label: "Minouche",
    value: "Minouche",
  },
  {
    label: "Minti",
    value: "Minti",
  },
  {
    label: "Minymo",
    value: "Minymo",
  },
  {
    label: "Miraclesuit",
    value: "Miraclesuit",
  },
  {
    label: "Mirella Dancewear",
    value: "Mirella Dancewear",
  },
  {
    label: "Mirth",
    value: "Mirth",
  },
  {
    label: "Misha Lulu",
    value: "Misha Lulu",
  },
  {
    label: "Misha & Puff",
    value: "Misha & Puff",
  },
  {
    label: "Miss Me",
    value: "Miss Me",
  },
  {
    label: "MLB",
    value: "MLB",
  },
  {
    label: "Moby Wrap",
    value: "Moby Wrap",
  },
  {
    label: "Mochi Kids",
    value: "Mochi Kids",
  },
  {
    label: "Modcloth",
    value: "Modcloth",
  },
  {
    label: "MODERN BABY",
    value: "MODERN BABY",
  },
  {
    label: "Modern Burlap",
    value: "Modern Burlap",
  },
  {
    label: "Mod Girl",
    value: "Mod Girl",
  },
  {
    label: "Moi",
    value: "Moi",
  },
  {
    label: "Moimoln",
    value: "Moimoln",
  },
  {
    label: "Molo",
    value: "Molo",
  },
  {
    label: "Momo Baby",
    value: "Momo Baby",
  },
  {
    label: "Moncler",
    value: "Moncler",
  },
  {
    label: "Mon Coeur",
    value: "Mon Coeur",
  },
  {
    label: "Monica + Andy",
    value: "Monica + Andy",
  },
  {
    label: "Monkey Feet",
    value: "Monkey Feet",
  },
  {
    label: "Monnalisa",
    value: "Monnalisa",
  },
  {
    label: "Mon Petit Shoes",
    value: "Mon Petit Shoes",
  },
  {
    label: "Monsoon",
    value: "Monsoon",
  },
  {
    label: "Monster + Eli",
    value: "Monster + Eli",
  },
  {
    label: "Monster High",
    value: "Monster High",
  },
  {
    label: "Monster Republic",
    value: "Monster Republic",
  },
  {
    label: "Monti Kids",
    value: "Monti Kids",
  },
  {
    label: "Moon Sprouts",
    value: "Moon Sprouts",
  },
  {
    label: "Mooshu",
    value: "Mooshu",
  },
  {
    label: "Morado Designs",
    value: "Morado Designs",
  },
  {
    label: "More Than Magic",
    value: "More Than Magic",
  },
  {
    label: "Moret Kids",
    value: "Moret Kids",
  },
  {
    label: "Morgan & Milo",
    value: "Morgan & Milo",
  },
  {
    label: "Morley",
    value: "Morley",
  },
  {
    label: "Mossimo Supply Co.",
    value: "Mossimo Supply Co.",
  },
  {
    label: "Mother",
    value: "Mother",
  },
  {
    label: "Motherhood Maternity",
    value: "Motherhood Maternity",
  },
  {
    label: "Motionwear",
    value: "Motionwear",
  },
  {
    label: "Motoreta",
    value: "Motoreta",
  },
  {
    label: "Motorola",
    value: "Motorola",
  },
  {
    label: "Moulin Roty",
    value: "Moulin Roty",
  },
  {
    label: "Mrly",
    value: "Mrly",
  },
  {
    label: "Mud Pie",
    value: "Mud Pie",
  },
  {
    label: "Muk Luks",
    value: "Muk Luks",
  },
  {
    label: "Mulberribush",
    value: "Mulberribush",
  },
  {
    label: "Munchkin",
    value: "Munchkin",
  },
  {
    label: "Munki Munki",
    value: "Munki Munki",
  },
  {
    label: "Munsterkids",
    value: "Munsterkids",
  },
  {
    label: "Muny",
    value: "Muny",
  },
  {
    label: "Mushie",
    value: "Mushie",
  },
  {
    label: "Musli",
    value: "Musli",
  },
  {
    label: "Mustard Pie",
    value: "Mustard Pie",
  },
  {
    label: "My Brother John",
    value: "My Brother John",
  },
  {
    label: "My Little Cozmo",
    value: "My Little Cozmo",
  },
  {
    label: "My Little Pony",
    value: "My Little Pony",
  },
  {
    label: "My Vintage Baby",
    value: "My Vintage Baby",
  },
  {
    label: "Naartjie",
    value: "Naartjie",
  },
  {
    label: "Nadadelazos",
    value: "Nadadelazos",
  },
  {
    label: "Nanit",
    value: "Nanit",
  },
  {
    label: "Nannette",
    value: "Nannette",
  },
  {
    label: "Nano Collection",
    value: "Nano Collection",
  },
  {
    label: "Nation LTD",
    value: "Nation LTD",
  },
  {
    label: "Native",
    value: "Native",
  },
  {
    label: "Native Fable",
    value: "Native Fable",
  },
  {
    label: "Nature Baby",
    value: "Nature Baby",
  },
  {
    label: "Naturino",
    value: "Naturino",
  },
  {
    label: "Natursutten",
    value: "Natursutten",
  },
  {
    label: "Nautica",
    value: "Nautica",
  },
  {
    label: "NBA",
    value: "NBA",
  },
  {
    label: "Neige",
    value: "Neige",
  },
  {
    label: "Nelly Madison",
    value: "Nelly Madison",
  },
  {
    label: "Nellystella",
    value: "Nellystella",
  },
  {
    label: "Nerf",
    value: "Nerf",
  },
  {
    label: "Nest Designs",
    value: "Nest Designs",
  },
  {
    label: "Nested Bean",
    value: "Nested Bean",
  },
  {
    label: "Neve & Hawk",
    value: "Neve & Hawk",
  },
  {
    label: "New Balance",
    value: "New Balance",
  },
  {
    label: "New Grain",
    value: "New Grain",
  },
  {
    label: "New York & Company",
    value: "New York & Company",
  },
  {
    label: "Next",
    value: "Next",
  },
  {
    label: "NFL",
    value: "NFL",
  },
  {
    label: "NHL",
    value: "NHL",
  },
  {
    label: "Nickelodeon",
    value: "Nickelodeon",
  },
  {
    label: "Nick & Nora",
    value: "Nick & Nora",
  },
  {
    label: "Nicole Miller",
    value: "Nicole Miller",
  },
  {
    label: "Nico Nico",
    value: "Nico Nico",
  },
  {
    label: "Nike",
    value: "Nike",
  },
  {
    label: "Nine West",
    value: "Nine West",
  },
  {
    label: "Nintendo",
    value: "Nintendo",
  },
  {
    label: "Nixi Lauroo",
    value: "Nixi Lauroo",
  },
  {
    label: "No Added Sugar",
    value: "No Added Sugar",
  },
  {
    label: "Noble",
    value: "Noble",
  },
  {
    label: "Noe & Zoe",
    value: "Noe & Zoe",
  },
  {
    label: "Nohi Kids",
    value: "Nohi Kids",
  },
  {
    label: "Noise with Dirt",
    value: "Noise with Dirt",
  },
  {
    label: "Nooks Design",
    value: "Nooks Design",
  },
  {
    label: "Noralee",
    value: "Noralee",
  },
  {
    label: "Nora Madison",
    value: "Nora Madison",
  },
  {
    label: "Nordstrom",
    value: "Nordstrom",
  },
  {
    label: "Nordstrom Baby",
    value: "Nordstrom Baby",
  },
  {
    label: "Northside",
    value: "Northside",
  },
  {
    label: "Noxx",
    value: "Noxx",
  },
  {
    label: "Nui Organics",
    value: "Nui Organics",
  },
  {
    label: "Nuk",
    value: "Nuk",
  },
  {
    label: "Numi",
    value: "Numi",
  },
  {
    label: "Nu Natives",
    value: "Nu Natives",
  },
  {
    label: "Nununu",
    value: "Nununu",
  },
  {
    label: "Nu Roo",
    value: "Nu Roo",
  },
  {
    label: "NYDJ",
    value: "NYDJ",
  },
  {
    label: "Oaki",
    value: "Oaki",
  },
  {
    label: "OAT",
    value: "OAT",
  },
  {
    label: "Obermeyer",
    value: "Obermeyer",
  },
  {
    label: "Obersee",
    value: "Obersee",
  },
  {
    label: "Obey Clothing",
    value: "Obey Clothing",
  },
  {
    label: "Octonauts",
    value: "Octonauts",
  },
  {
    label: "Odin & Ivy",
    value: "Odin & Ivy",
  },
  {
    label: "Oeuf",
    value: "Oeuf",
  },
  {
    label: "Of One Sea",
    value: "Of One Sea",
  },
  {
    label: "Oh Baby!",
    value: "Oh Baby!",
  },
  {
    label: "Oilily",
    value: "Oilily",
  },
  {
    label: "Oiselle",
    value: "Oiselle",
  },
  {
    label: "Okaa",
    value: "Okaa",
  },
  {
    label: "Okaidi",
    value: "Okaidi",
  },
  {
    label: "Okie Dokie",
    value: "Okie Dokie",
  },
  {
    label: "Old Navy",
    value: "Old Navy",
  },
  {
    label: "Old Soles",
    value: "Old Soles",
  },
  {
    label: "Oli&Carol",
    value: "Oli&Carol",
  },
  {
    label: "Olive Juice",
    value: "Olive Juice",
  },
  {
    label: "Olive Mae",
    value: "Olive Mae",
  },
  {
    label: "Oliver Peoples",
    value: "Oliver Peoples",
  },
  {
    label: "Oliver & Rain",
    value: "Oliver & Rain",
  },
  {
    label: "Olivia J",
    value: "Olivia J",
  },
  {
    label: "Olivia + Ocean",
    value: "Olivia + Ocean",
  },
  {
    label: "Olivier London",
    value: "Olivier London",
  },
  {
    label: "Ollie Baby",
    value: "Ollie Baby",
  },
  {
    label: "Olli Ella",
    value: "Olli Ella",
  },
  {
    label: "OluKai",
    value: "OluKai",
  },
  {
    label: "Omamimini",
    value: "Omamimini",
  },
  {
    label: "Omi Jo",
    value: "Omi Jo",
  },
  {
    label: "One Day Parade",
    value: "One Day Parade",
  },
  {
    label: "O'Neill",
    value: "O'Neill",
  },
  {
    label: "One Jackson",
    value: "One Jackson",
  },
  {
    label: "OneKid",
    value: "OneKid",
  },
  {
    label: "One Teaspoon",
    value: "One Teaspoon",
  },
  {
    label: "Only Little Once",
    value: "Only Little Once",
  },
  {
    label: "On Running",
    value: "On Running",
  },
  {
    label: "Ooh La La Couture",
    value: "Ooh La La Couture",
  },
  {
    label: "Oomphies",
    value: "Oomphies",
  },
  {
    label: "Open Edit",
    value: "Open Edit",
  },
  {
    label: "Opposite of Far",
    value: "Opposite of Far",
  },
  {
    label: "Orcas Lucille",
    value: "Orcas Lucille",
  },
  {
    label: "Orchard Toys",
    value: "Orchard Toys",
  },
  {
    label: "Organic Zoo",
    value: "Organic Zoo",
  },
  {
    label: "Orient Expressed",
    value: "Orient Expressed",
  },
  {
    label: "Origany",
    value: "Origany",
  },
  {
    label: "Original Marines",
    value: "Original Marines",
  },
  {
    label: "Oscar de la Renta",
    value: "Oscar de la Renta",
  },
  {
    label: "OshKosh",
    value: "OshKosh",
  },
  {
    label: "Oso & Me",
    value: "Oso & Me",
  },
  {
    label: "Ostheimer",
    value: "Ostheimer",
  },
  {
    label: "Other",
    value: "Other",
  },
  {
    label: "Our Generation",
    value: "Our Generation",
  },
  {
    label: "Outdoor Voices",
    value: "Outdoor Voices",
  },
  {
    label: "Out Of Print",
    value: "Out Of Print",
  },
  {
    label: "Ovation",
    value: "Ovation",
  },
  {
    label: "Owlet",
    value: "Owlet",
  },
  {
    label: "Owlivia",
    value: "Owlivia",
  },
  {
    label: "Oxo",
    value: "Oxo",
  },
  {
    label: "Ozone Leotards",
    value: "Ozone Leotards",
  },
  {
    label: "PacSun",
    value: "PacSun",
  },
  {
    label: "Pact",
    value: "Pact",
  },
  {
    label: "Paige Denim",
    value: "Paige Denim",
  },
  {
    label: "Paige Lauren",
    value: "Paige Lauren",
  },
  {
    label: "Pale Cloud",
    value: "Pale Cloud",
  },
  {
    label: "Palladium",
    value: "Palladium",
  },
  {
    label: "Panache",
    value: "Panache",
  },
  {
    label: "Panyno",
    value: "Panyno",
  },
  {
    label: "Paper Denim & Cloth",
    value: "Paper Denim & Cloth",
  },
  {
    label: "PaperKrane",
    value: "PaperKrane",
  },
  {
    label: "Papermoon",
    value: "Papermoon",
  },
  {
    label: "Paper Wings",
    value: "Paper Wings",
  },
  {
    label: "Papo d' Anjo",
    value: "Papo d' Anjo",
  },
  {
    label: "Papu",
    value: "Papu",
  },
  {
    label: "Parachute",
    value: "Parachute",
  },
  {
    label: "Parade",
    value: "Parade",
  },
  {
    label: "Passive Juice Motel",
    value: "Passive Juice Motel",
  },
  {
    label: "Patagonia",
    value: "Patagonia",
  },
  {
    label: "Paulina Quintana",
    value: "Paulina Quintana",
  },
  {
    label: "Paul Smith",
    value: "Paul Smith",
  },
  {
    label: "Paush",
    value: "Paush",
  },
  {
    label: "Paw Patrol",
    value: "Paw Patrol",
  },
  {
    label: "Peaches 'n Cream",
    value: "Peaches 'n Cream",
  },
  {
    label: "Peanut Shell",
    value: "Peanut Shell",
  },
  {
    label: "Peas and Queues",
    value: "Peas and Queues",
  },
  {
    label: "Pediped",
    value: "Pediped",
  },
  {
    label: "Peek",
    value: "Peek",
  },
  {
    label: "Peg Perego",
    value: "Peg Perego",
  },
  {
    label: "Pehr",
    value: "Pehr",
  },
  {
    label: "Penelope Mack",
    value: "Penelope Mack",
  },
  {
    label: "Penguin",
    value: "Penguin",
  },
  {
    label: "PePe Shoes",
    value: "PePe Shoes",
  },
  {
    label: "Peppa Pig",
    value: "Peppa Pig",
  },
  {
    label: "Peregrine",
    value: "Peregrine",
  },
  {
    label: "Periwinkle Jazz",
    value: "Periwinkle Jazz",
  },
  {
    label: "Persnickety",
    value: "Persnickety",
  },
  {
    label: "Petal + Pup",
    value: "Petal + Pup",
  },
  {
    label: "Pete + Lucy",
    value: "Pete + Lucy",
  },
  {
    label: "Petit Ami",
    value: "Petit Ami",
  },
  {
    label: "Petit Bateau",
    value: "Petit Bateau",
  },
  {
    label: "Petit Bebe",
    value: "Petit Bebe",
  },
  {
    label: "Petitbo",
    value: "Petitbo",
  },
  {
    label: "Petite Adele",
    value: "Petite Adele",
  },
  {
    label: "Petite Amelia",
    value: "Petite Amelia",
  },
  {
    label: "Petite Hailey",
    value: "Petite Hailey",
  },
  {
    label: "Petite Plume",
    value: "Petite Plume",
  },
  {
    label: "Petit Lem",
    value: "Petit Lem",
  },
  {
    label: "Petit Patapon",
    value: "Petit Patapon",
  },
  {
    label: "Petit Peony",
    value: "Petit Peony",
  },
  {
    label: "Petits Marcheurs",
    value: "Petits Marcheurs",
  },
  {
    label: "Petits Vilains",
    value: "Petits Vilains",
  },
  {
    label: "Petit Vilains",
    value: "Petit Vilains",
  },
  {
    label: "Petunia Pickle Bottom",
    value: "Petunia Pickle Bottom",
  },
  {
    label: "Peyper Kids",
    value: "Peyper Kids",
  },
  {
    label: "Philosophy",
    value: "Philosophy",
  },
  {
    label: "Phil&Phae",
    value: "Phil&Phae",
  },
  {
    label: "Piccalilly",
    value: "Piccalilly",
  },
  {
    label: "Piccolina Kids",
    value: "Piccolina Kids",
  },
  {
    label: "PinkBlush",
    value: "PinkBlush",
  },
  {
    label: "Pink by Victoria's Secret",
    value: "Pink by Victoria's Secret",
  },
  {
    label: "Pink Chicken",
    value: "Pink Chicken",
  },
  {
    label: "Piper Finn",
    value: "Piper Finn",
  },
  {
    label: "Pippa & Julie",
    value: "Pippa & Julie",
  },
  {
    label: "Pistola",
    value: "Pistola",
  },
  {
    label: "Pistol + Arrow",
    value: "Pistol + Arrow",
  },
  {
    label: "Piupiuchick",
    value: "Piupiuchick",
  },
  {
    label: "PixieLane",
    value: "PixieLane",
  },
  {
    label: "PJ Masks",
    value: "PJ Masks",
  },
  {
    label: "PJ Salvage",
    value: "PJ Salvage",
  },
  {
    label: "Placard Originals",
    value: "Placard Originals",
  },
  {
    label: "Plae",
    value: "Plae",
  },
  {
    label: "Plain Jane",
    value: "Plain Jane",
  },
  {
    label: "Planetbox",
    value: "Planetbox",
  },
  {
    label: "Planet Wise",
    value: "Planet Wise",
  },
  {
    label: "PlanToys",
    value: "PlanToys",
  },
  {
    label: "Play-Doh",
    value: "Play-Doh",
  },
  {
    label: "Playmobil",
    value: "Playmobil",
  },
  {
    label: "Playskool",
    value: "Playskool",
  },
  {
    label: "Play Up",
    value: "Play Up",
  },
  {
    label: "Play X Play",
    value: "Play X Play",
  },
  {
    label: "Pleiades Designs",
    value: "Pleiades Designs",
  },
  {
    label: "Plush",
    value: "Plush",
  },
  {
    label: "Pokemon",
    value: "Pokemon",
  },
  {
    label: "Polarn O. Pyret",
    value: "Polarn O. Pyret",
  },
  {
    label: "Polly Flinders",
    value: "Polly Flinders",
  },
  {
    label: "Pom d'Api",
    value: "Pom d'Api",
  },
  {
    label: "Ponponia",
    value: "Ponponia",
  },
  {
    label: "Pons",
    value: "Pons",
  },
  {
    label: "Pony",
    value: "Pony",
  },
  {
    label: "Popatu",
    value: "Popatu",
  },
  {
    label: "Poppie",
    value: "Poppie",
  },
  {
    label: "Poppyseed Play",
    value: "Poppyseed Play",
  },
  {
    label: "Popupshop",
    value: "Popupshop",
  },
  {
    label: "Posey Wear",
    value: "Posey Wear",
  },
  {
    label: "Posh Kiddos",
    value: "Posh Kiddos",
  },
  {
    label: "Posh Peanut",
    value: "Posh Peanut",
  },
  {
    label: "Pottery Barn Kids",
    value: "Pottery Barn Kids",
  },
  {
    label: "Poudre Organic",
    value: "Poudre Organic",
  },
  {
    label: "Powell Craft",
    value: "Powell Craft",
  },
  {
    label: "Power Rangers",
    value: "Power Rangers",
  },
  {
    label: "Prada",
    value: "Prada",
  },
  {
    label: "Prana",
    value: "Prana",
  },
  {
    label: "Prefresh",
    value: "Prefresh",
  },
  {
    label: "Pretty Please Teethers",
    value: "Pretty Please Teethers",
  },
  {
    label: "Primark",
    value: "Primark",
  },
  {
    label: "Primary",
    value: "Primary",
  },
  {
    label: "Primigi",
    value: "Primigi",
  },
  {
    label: "Princess Awesome",
    value: "Princess Awesome",
  },
  {
    label: "Prodoh",
    value: "Prodoh",
  },
  {
    label: "Properly Tied",
    value: "Properly Tied",
  },
  {
    label: "P'tula",
    value: "P'tula",
  },
  {
    label: "Puddle Jumper",
    value: "Puddle Jumper",
  },
  {
    label: "Puma",
    value: "Puma",
  },
  {
    label: "Pumpkin Patch",
    value: "Pumpkin Patch",
  },
  {
    label: "Purebaby",
    value: "Purebaby",
  },
  {
    label: "Purl Lamb",
    value: "Purl Lamb",
  },
  {
    label: "Question Everything",
    value: "Question Everything",
  },
  {
    label: "Quiksilver",
    value: "Quiksilver",
  },
  {
    label: "Quincy Mae",
    value: "Quincy Mae",
  },
  {
    label: "Quinn & Fox",
    value: "Quinn & Fox",
  },
  {
    label: "Rabbit + Bear",
    value: "Rabbit + Bear",
  },
  {
    label: "Rabbit Moon",
    value: "Rabbit Moon",
  },
  {
    label: "Rachel Riley",
    value: "Rachel Riley",
  },
  {
    label: "Rachel Zoe",
    value: "Rachel Zoe",
  },
  {
    label: "Rad Revolution",
    value: "Rad Revolution",
  },
  {
    label: "Raduga Grez",
    value: "Raduga Grez",
  },
  {
    label: "Rae Dunn",
    value: "Rae Dunn",
  },
  {
    label: "Rag & Bone",
    value: "Rag & Bone",
  },
  {
    label: "Rags",
    value: "Rags",
  },
  {
    label: "Rainbow Sandals",
    value: "Rainbow Sandals",
  },
  {
    label: "Raine+Skye",
    value: "Raine+Skye",
  },
  {
    label: "Raised by water",
    value: "Raised by water",
  },
  {
    label: "Raising Wild",
    value: "Raising Wild",
  },
  {
    label: "Raising Wilds",
    value: "Raising Wilds",
  },
  {
    label: "Raisins Swimwear",
    value: "Raisins Swimwear",
  },
  {
    label: "Ralph Lauren",
    value: "Ralph Lauren",
  },
  {
    label: "Rare Editions",
    value: "Rare Editions",
  },
  {
    label: "Raspberry Republic",
    value: "Raspberry Republic",
  },
  {
    label: "Ravensburger",
    value: "Ravensburger",
  },
  {
    label: "Raxtin Clothing Co",
    value: "Raxtin Clothing Co",
  },
  {
    label: "Ray-Ban",
    value: "Ray-Ban",
  },
  {
    label: "Rebecca Minkoff",
    value: "Rebecca Minkoff",
  },
  {
    label: "Red Caribou",
    value: "Red Caribou",
  },
  {
    label: "ReDone",
    value: "ReDone",
  },
  {
    label: "Red Wing Shoes",
    value: "Red Wing Shoes",
  },
  {
    label: "Reebok",
    value: "Reebok",
  },
  {
    label: "Reef",
    value: "Reef",
  },
  {
    label: "Reformation",
    value: "Reformation",
  },
  {
    label: "REI",
    value: "REI",
  },
  {
    label: "Reima",
    value: "Reima",
  },
  {
    label: "Relished",
    value: "Relished",
  },
  {
    label: "Remember Nguyen",
    value: "Remember Nguyen",
  },
  {
    label: "Remie Girl",
    value: "Remie Girl",
  },
  {
    label: "Rene Rofe",
    value: "Rene Rofe",
  },
  {
    label: "Repetto",
    value: "Repetto",
  },
  {
    label: "Restoration Hardware",
    value: "Restoration Hardware",
  },
  {
    label: "Retro Brand",
    value: "Retro Brand",
  },
  {
    label: "Revolve",
    value: "Revolve",
  },
  {
    label: "Ricrac & Ruffles",
    value: "Ricrac & Ruffles",
  },
  {
    label: "Rifle Paper Co.",
    value: "Rifle Paper Co.",
  },
  {
    label: "Right Bank Babies",
    value: "Right Bank Babies",
  },
  {
    label: "Riley Roo",
    value: "Riley Roo",
  },
  {
    label: "River Babe Threads",
    value: "River Babe Threads",
  },
  {
    label: "River Island",
    value: "River Island",
  },
  {
    label: "Rivet Apparel Co.",
    value: "Rivet Apparel Co.",
  },
  {
    label: "Robeez",
    value: "Robeez",
  },
  {
    label: "Roberta Roller Rabbit",
    value: "Roberta Roller Rabbit",
  },
  {
    label: "Robin Piccone",
    value: "Robin Piccone",
  },
  {
    label: "Roch Valley",
    value: "Roch Valley",
  },
  {
    label: "Rocker Baby Nation",
    value: "Rocker Baby Nation",
  },
  {
    label: "Rocket Dog",
    value: "Rocket Dog",
  },
  {
    label: "Rockets of Awesome",
    value: "Rockets of Awesome",
  },
  {
    label: "Rockin' Baby",
    value: "Rockin' Baby",
  },
  {
    label: "Rock & Republic",
    value: "Rock & Republic",
  },
  {
    label: "RockRevival",
    value: "RockRevival",
  },
  {
    label: "Rock Your Baby",
    value: "Rock Your Baby",
  },
  {
    label: "Rolly Polly",
    value: "Rolly Polly",
  },
  {
    label: "Romey Loves Lulu",
    value: "Romey Loves Lulu",
  },
  {
    label: "Roolee",
    value: "Roolee",
  },
  {
    label: "Room Seven",
    value: "Room Seven",
  },
  {
    label: "Roots",
    value: "Roots",
  },
  {
    label: "Roper",
    value: "Roper",
  },
  {
    label: "Rorie Whelan",
    value: "Rorie Whelan",
  },
  {
    label: "Rosa Flamenco",
    value: "Rosa Flamenco",
  },
  {
    label: "Rosalina",
    value: "Rosalina",
  },
  {
    label: "Rose & Belle",
    value: "Rose & Belle",
  },
  {
    label: "Rosie Pope",
    value: "Rosie Pope",
  },
  {
    label: "Rothschild",
    value: "Rothschild",
  },
  {
    label: "Rothy's",
    value: "Rothy's",
  },
  {
    label: "Rowdy Sprout",
    value: "Rowdy Sprout",
  },
  {
    label: "Roxy",
    value: "Roxy",
  },
  {
    label: "Ruby & Bloom",
    value: "Ruby & Bloom",
  },
  {
    label: "RU Co Australia",
    value: "RU Co Australia",
  },
  {
    label: "Rudy Jude",
    value: "Rudy Jude",
  },
  {
    label: "Rue21",
    value: "Rue21",
  },
  {
    label: "Rue Muu",
    value: "Rue Muu",
  },
  {
    label: "RuffleButts",
    value: "RuffleButts",
  },
  {
    label: "Rumi + Ryder",
    value: "Rumi + Ryder",
  },
  {
    label: "Rumparooz",
    value: "Rumparooz",
  },
  {
    label: "Run About Clothing",
    value: "Run About Clothing",
  },
  {
    label: "Runway",
    value: "Runway",
  },
  {
    label: "Rustic Pickle",
    value: "Rustic Pickle",
  },
  {
    label: "RUUM",
    value: "RUUM",
  },
  {
    label: "Ryan & Rose",
    value: "Ryan & Rose",
  },
  {
    label: "Rylee + Cru",
    value: "Rylee + Cru",
  },
  {
    label: "Sadie Then Ty",
    value: "Sadie Then Ty",
  },
  {
    label: "Sado",
    value: "Sado",
  },
  {
    label: "Saint Haven",
    value: "Saint Haven",
  },
  {
    label: "Saint Ida",
    value: "Saint Ida",
  },
  {
    label: "Sakura",
    value: "Sakura",
  },
  {
    label: "Salomon",
    value: "Salomon",
  },
  {
    label: "Sal&Pimenta",
    value: "Sal&Pimenta",
  },
  {
    label: "Salt City Emporium",
    value: "Salt City Emporium",
  },
  {
    label: "Salt Water",
    value: "Salt Water",
  },
  {
    label: "Salty Little Bums",
    value: "Salty Little Bums",
  },
  {
    label: "Salty Shreds",
    value: "Salty Shreds",
  },
  {
    label: "Sam Edelman",
    value: "Sam Edelman",
  },
  {
    label: "Sanctuary",
    value: "Sanctuary",
  },
  {
    label: "San Diego Hat Company",
    value: "San Diego Hat Company",
  },
  {
    label: "Sanuk",
    value: "Sanuk",
  },
  {
    label: "Sapling Child",
    value: "Sapling Child",
  },
  {
    label: "Sarah Louise",
    value: "Sarah Louise",
  },
  {
    label: "Satara Leos",
    value: "Satara Leos",
  },
  {
    label: "Saucony",
    value: "Saucony",
  },
  {
    label: "Savage Seeds",
    value: "Savage Seeds",
  },
  {
    label: "Savannah",
    value: "Savannah",
  },
  {
    label: "Save The Duck",
    value: "Save The Duck",
  },
  {
    label: "Sawyer",
    value: "Sawyer",
  },
  {
    label: "Sawyer & Finn",
    value: "Sawyer & Finn",
  },
  {
    label: "Schleich",
    value: "Schleich",
  },
  {
    label: "Scholastic",
    value: "Scholastic",
  },
  {
    label: "Schylling",
    value: "Schylling",
  },
  {
    label: "Sckoon Organic",
    value: "Sckoon Organic",
  },
  {
    label: "Scotch R'Belle",
    value: "Scotch R'Belle",
  },
  {
    label: "Scotch Shrunk",
    value: "Scotch Shrunk",
  },
  {
    label: "Scotch & Soda",
    value: "Scotch & Soda",
  },
  {
    label: "Seaesta Surf",
    value: "Seaesta Surf",
  },
  {
    label: "Seafolly",
    value: "Seafolly",
  },
  {
    label: "Seed Heritage",
    value: "Seed Heritage",
  },
  {
    label: "See Kai Run",
    value: "See Kai Run",
  },
  {
    label: "Self Esteem",
    value: "Self Esteem",
  },
  {
    label: "Seraphine",
    value: "Seraphine",
  },
  {
    label: "Sergent Major",
    value: "Sergent Major",
  },
  {
    label: "Sesame Street",
    value: "Sesame Street",
  },
  {
    label: "Seven Slings",
    value: "Seven Slings",
  },
  {
    label: "Seviin",
    value: "Seviin",
  },
  {
    label: "Sew Sassy",
    value: "Sew Sassy",
  },
  {
    label: "Seychelles",
    value: "Seychelles",
  },
  {
    label: "Shade Critters",
    value: "Shade Critters",
  },
  {
    label: "Shein",
    value: "Shein",
  },
  {
    label: "Shine Cloth",
    value: "Shine Cloth",
  },
  {
    label: "Shop Dos (Dōs)",
    value: "Shop Dos (Dōs)",
  },
  {
    label: "Shopkins",
    value: "Shopkins",
  },
  {
    label: "Shrimp & Grits Kids",
    value: "Shrimp & Grits Kids",
  },
  {
    label: "Siaomimi",
    value: "Siaomimi",
  },
  {
    label: "Silkberry",
    value: "Silkberry",
  },
  {
    label: "Silly Silas",
    value: "Silly Silas",
  },
  {
    label: "Silver Cross",
    value: "Silver Cross",
  },
  {
    label: "Silver Jeans Co.",
    value: "Silver Jeans Co.",
  },
  {
    label: "Simonetta",
    value: "Simonetta",
  },
  {
    label: "Simple Sawyer",
    value: "Simple Sawyer",
  },
  {
    label: "Skechers",
    value: "Skechers",
  },
  {
    label: "Skip Hop",
    value: "Skip Hop",
  },
  {
    label: "Skylar Luna",
    value: "Skylar Luna",
  },
  {
    label: "Sleeping Baby",
    value: "Sleeping Baby",
  },
  {
    label: "Sloomb",
    value: "Sloomb",
  },
  {
    label: "Slugs & Snails",
    value: "Slugs & Snails",
  },
  {
    label: "Slumberkins",
    value: "Slumberkins",
  },
  {
    label: "Slvrlake",
    value: "Slvrlake",
  },
  {
    label: "Slyfox Threads",
    value: "Slyfox Threads",
  },
  {
    label: "Smafolk",
    value: "Smafolk",
  },
  {
    label: "Small Lot",
    value: "Small Lot",
  },
  {
    label: "Smart Bottoms",
    value: "Smart Bottoms",
  },
  {
    label: "SmartGames",
    value: "SmartGames",
  },
  {
    label: "Smartwool",
    value: "Smartwool",
  },
  {
    label: "Smiling Button",
    value: "Smiling Button",
  },
  {
    label: "Smocked Auctions",
    value: "Smocked Auctions",
  },
  {
    label: "Smockingbird",
    value: "Smockingbird",
  },
  {
    label: "Snapper Rock",
    value: "Snapper Rock",
  },
  {
    label: "Snowflake Designs",
    value: "Snowflake Designs",
  },
  {
    label: "Snugabye",
    value: "Snugabye",
  },
  {
    label: "SO",
    value: "SO",
  },
  {
    label: "Socialite",
    value: "Socialite",
  },
  {
    label: "So Danca",
    value: "So Danca",
  },
  {
    label: "Soffe",
    value: "Soffe",
  },
  {
    label: "Soft Gallery",
    value: "Soft Gallery",
  },
  {
    label: "SoftStar",
    value: "SoftStar",
  },
  {
    label: "Soft Surroundings",
    value: "Soft Surroundings",
  },
  {
    label: "Sol Angeles",
    value: "Sol Angeles",
  },
  {
    label: "Solly Baby",
    value: "Solly Baby",
  },
  {
    label: "Soludos",
    value: "Soludos",
  },
  {
    label: "Soma",
    value: "Soma",
  },
  {
    label: "Sonnet James",
    value: "Sonnet James",
  },
  {
    label: "Sonoma",
    value: "Sonoma",
  },
  {
    label: "Soor Ploom",
    value: "Soor Ploom",
  },
  {
    label: "Sophia Webster",
    value: "Sophia Webster",
  },
  {
    label: "Sophie and Sam",
    value: "Sophie and Sam",
  },
  {
    label: "Sophie Catalou",
    value: "Sophie Catalou",
  },
  {
    label: "Sorel",
    value: "Sorel",
  },
  {
    label: "Southern Style",
    value: "Southern Style",
  },
  {
    label: "Southern Sunshine Kids",
    value: "Southern Sunshine Kids",
  },
  {
    label: "Southern Tide",
    value: "Southern Tide",
  },
  {
    label: "Southern Tots",
    value: "Southern Tots",
  },
  {
    label: "Sova",
    value: "Sova",
  },
  {
    label: "Sovereign Code",
    value: "Sovereign Code",
  },
  {
    label: "Spanx",
    value: "Spanx",
  },
  {
    label: "SpearmintLOVE",
    value: "SpearmintLOVE",
  },
  {
    label: "Speechless",
    value: "Speechless",
  },
  {
    label: "Speedo",
    value: "Speedo",
  },
  {
    label: "Sperry",
    value: "Sperry",
  },
  {
    label: "Spirit Halloween",
    value: "Spirit Halloween",
  },
  {
    label: "Spiritual Gangster",
    value: "Spiritual Gangster",
  },
  {
    label: "Splendid",
    value: "Splendid",
  },
  {
    label: "Sproet & Sprout",
    value: "Sproet & Sprout",
  },
  {
    label: "Spyder",
    value: "Spyder",
  },
  {
    label: "Squeaky",
    value: "Squeaky",
  },
  {
    label: "Squirtini Bikini",
    value: "Squirtini Bikini",
  },
  {
    label: "Squishmallows",
    value: "Squishmallows",
  },
  {
    label: "St. Agni",
    value: "St. Agni",
  },
  {
    label: "Stance",
    value: "Stance",
  },
  {
    label: "Starry Knight",
    value: "Starry Knight",
  },
  {
    label: "Star Wars",
    value: "Star Wars",
  },
  {
    label: "State Bags",
    value: "State Bags",
  },
  {
    label: "Stella Cove",
    value: "Stella Cove",
  },
  {
    label: "Stella & Dot",
    value: "Stella & Dot",
  },
  {
    label: "Stella McCartney",
    value: "Stella McCartney",
  },
  {
    label: "Stella + Wilbur",
    value: "Stella + Wilbur",
  },
  {
    label: "Stellybelly",
    value: "Stellybelly",
  },
  {
    label: "Stem",
    value: "Stem",
  },
  {
    label: "Steve Madden",
    value: "Steve Madden",
  },
  {
    label: "Stokke",
    value: "Stokke",
  },
  {
    label: "Stonz",
    value: "Stonz",
  },
  {
    label: "Storksak",
    value: "Storksak",
  },
  {
    label: "StorkSak",
    value: "StorkSak",
  },
  {
    label: "Storq",
    value: "Storq",
  },
  {
    label: "Strasburg Children",
    value: "Strasburg Children",
  },
  {
    label: "Stride Rite",
    value: "Stride Rite",
  },
  {
    label: "Stuart Weitzman",
    value: "Stuart Weitzman",
  },
  {
    label: "Summer In May",
    value: "Summer In May",
  },
  {
    label: "Sun and Lace",
    value: "Sun and Lace",
  },
  {
    label: "Sundance",
    value: "Sundance",
  },
  {
    label: "Sundek",
    value: "Sundek",
  },
  {
    label: "Sundry",
    value: "Sundry",
  },
  {
    label: "Sunnie Hunnies",
    value: "Sunnie Hunnies",
  },
  {
    label: "Sunshine Swing",
    value: "Sunshine Swing",
  },
  {
    label: "Sunuva",
    value: "Sunuva",
  },
  {
    label: "Supayana",
    value: "Supayana",
  },
  {
    label: "Superdry",
    value: "Superdry",
  },
  {
    label: "Superga",
    value: "Superga",
  },
  {
    label: "Superism",
    value: "Superism",
  },
  {
    label: "Supra",
    value: "Supra",
  },
  {
    label: "Supreme",
    value: "Supreme",
  },
  {
    label: "Suunday",
    value: "Suunday",
  },
  {
    label: "Sven Clogs",
    value: "Sven Clogs",
  },
  {
    label: "SwaddleMe",
    value: "SwaddleMe",
  },
  {
    label: "Swanky Baby Vintage",
    value: "Swanky Baby Vintage",
  },
  {
    label: "Swanky Shank",
    value: "Swanky Shank",
  },
  {
    label: "Sweaty Betty",
    value: "Sweaty Betty",
  },
  {
    label: "Sweet Bamboo",
    value: "Sweet Bamboo",
  },
  {
    label: "SweetHoney",
    value: "SweetHoney",
  },
  {
    label: "Sweet Luka Mo",
    value: "Sweet Luka Mo",
  },
  {
    label: "SweetNSwag",
    value: "SweetNSwag",
  },
  {
    label: "Sweet Peanut",
    value: "Sweet Peanut",
  },
  {
    label: "Sweet Petunia",
    value: "Sweet Petunia",
  },
  {
    label: "Sweet Wink",
    value: "Sweet Wink",
  },
  {
    label: "Swoon Baby Clothing",
    value: "Swoon Baby Clothing",
  },
  {
    label: "Sylvia P",
    value: "Sylvia P",
  },
  {
    label: "Tahari",
    value: "Tahari",
  },
  {
    label: "Tailor Vintage",
    value: "Tailor Vintage",
  },
  {
    label: "Talbots",
    value: "Talbots",
  },
  {
    label: "Tane Organics",
    value: "Tane Organics",
  },
  {
    label: "Taos",
    value: "Taos",
  },
  {
    label: "Tartine et Chocolat",
    value: "Tartine et Chocolat",
  },
  {
    label: "Tate & Tess",
    value: "Tate & Tess",
  },
  {
    label: "Taylor Joelle",
    value: "Taylor Joelle",
  },
  {
    label: "Tea Collection",
    value: "Tea Collection",
  },
  {
    label: "Ted Baker",
    value: "Ted Baker",
  },
  {
    label: "Tegu",
    value: "Tegu",
  },
  {
    label: "Tekhni Wovens",
    value: "Tekhni Wovens",
  },
  {
    label: "Telegraph Ave",
    value: "Telegraph Ave",
  },
  {
    label: "Tender Leaf Toys",
    value: "Tender Leaf Toys",
  },
  {
    label: "Ten Little",
    value: "Ten Little",
  },
  {
    label: "Tenth & Pine",
    value: "Tenth & Pine",
  },
  {
    label: "Terez",
    value: "Terez",
  },
  {
    label: "Terra",
    value: "Terra",
  },
  {
    label: "Tesa Babe",
    value: "Tesa Babe",
  },
  {
    label: "Teva",
    value: "Teva",
  },
  {
    label: "The Animals Observatory",
    value: "The Animals Observatory",
  },
  {
    label: "The Beaufort Bonnet Company",
    value: "The Beaufort Bonnet Company",
  },
  {
    label: "The Bee & The Fox",
    value: "The Bee & The Fox",
  },
  {
    label: "The Blueberry Hill",
    value: "The Blueberry Hill",
  },
  {
    label: "The Bonnie Mob",
    value: "The Bonnie Mob",
  },
  {
    label: "The Campamento",
    value: "The Campamento",
  },
  {
    label: "The Company Store",
    value: "The Company Store",
  },
  {
    label: "The Ever Co",
    value: "The Ever Co",
  },
  {
    label: "THE GREAT.",
    value: "THE GREAT.",
  },
  {
    label: "The Humble Soles",
    value: "The Humble Soles",
  },
  {
    label: "The Limited",
    value: "The Limited",
  },
  {
    label: "The Little Posh Co",
    value: "The Little Posh Co",
  },
  {
    label: "The Little Surfer Dude",
    value: "The Little Surfer Dude",
  },
  {
    label: "The Magpie Co.",
    value: "The Magpie Co.",
  },
  {
    label: "The Measure",
    value: "The Measure",
  },
  {
    label: "THE NEU CO.",
    value: "THE NEU CO.",
  },
  {
    label: "The New Society",
    value: "The New Society",
  },
  {
    label: "The North Face",
    value: "The North Face",
  },
  {
    label: "The Oaks Apparel",
    value: "The Oaks Apparel",
  },
  {
    label: "The Ollie World",
    value: "The Ollie World",
  },
  {
    label: "Theory",
    value: "Theory",
  },
  {
    label: "The Paper Doll Clothing",
    value: "The Paper Doll Clothing",
  },
  {
    label: "The Patchery",
    value: "The Patchery",
  },
  {
    label: "The Proper Peony",
    value: "The Proper Peony",
  },
  {
    label: "The Simple Folk",
    value: "The Simple Folk",
  },
  {
    label: "The Wishing Elephant",
    value: "The Wishing Elephant",
  },
  {
    label: "Thief and Bandit",
    value: "Thief and Bandit",
  },
  {
    label: "Thimble Collection",
    value: "Thimble Collection",
  },
  {
    label: "ThinkFun",
    value: "ThinkFun",
  },
  {
    label: "Thirsties",
    value: "Thirsties",
  },
  {
    label: "This Tribe of Three",
    value: "This Tribe of Three",
  },
  {
    label: "Thomas & Friends",
    value: "Thomas & Friends",
  },
  {
    label: "Thule",
    value: "Thule",
  },
  {
    label: "Tiffany & Co.",
    value: "Tiffany & Co.",
  },
  {
    label: "Tiger Friday",
    value: "Tiger Friday",
  },
  {
    label: "Timberland",
    value: "Timberland",
  },
  {
    label: "Timi & Leslie",
    value: "Timi & Leslie",
  },
  {
    label: "Tiny Alpaca",
    value: "Tiny Alpaca",
  },
  {
    label: "Tiny Bandit",
    value: "Tiny Bandit",
  },
  {
    label: "Tinycottons",
    value: "Tinycottons",
  },
  {
    label: "Tiny Tribe",
    value: "Tiny Tribe",
  },
  {
    label: "Tiny Whales",
    value: "Tiny Whales",
  },
  {
    label: "Title Nine",
    value: "Title Nine",
  },
  {
    label: "Tkees",
    value: "Tkees",
  },
  {
    label: "TKS",
    value: "TKS",
  },
  {
    label: "Toby Tiger",
    value: "Toby Tiger",
  },
  {
    label: "Tocoto Vintage",
    value: "Tocoto Vintage",
  },
  {
    label: "Tom & Drew",
    value: "Tom & Drew",
  },
  {
    label: "Tommee Tippee",
    value: "Tommee Tippee",
  },
  {
    label: "Tommy Bahama",
    value: "Tommy Bahama",
  },
  {
    label: "Tommy Hilfiger",
    value: "Tommy Hilfiger",
  },
  {
    label: "Toms",
    value: "Toms",
  },
  {
    label: "Tom & Teddy",
    value: "Tom & Teddy",
  },
  {
    label: "Tonies",
    value: "Tonies",
  },
  {
    label: "Toobydoo",
    value: "Toobydoo",
  },
  {
    label: "Tootsa MacGinty",
    value: "Tootsa MacGinty",
  },
  {
    label: "Topshop",
    value: "Topshop",
  },
  {
    label: "Torrid",
    value: "Torrid",
  },
  {
    label: "Tory Burch",
    value: "Tory Burch",
  },
  {
    label: "Touched by Nature",
    value: "Touched by Nature",
  },
  {
    label: "Toys R Us",
    value: "Toys R Us",
  },
  {
    label: "TØY ( Toey )",
    value: "TØY ( Toey )",
  },
  {
    label: "Tractr",
    value: "Tractr",
  },
  {
    label: "Tralala",
    value: "Tralala",
  },
  {
    label: "Transformers",
    value: "Transformers",
  },
  {
    label: "Trave",
    value: "Trave",
  },
  {
    label: "Treasure & Bond",
    value: "Treasure & Bond",
  },
  {
    label: "Tribe Is Alive",
    value: "Tribe Is Alive",
  },
  {
    label: "Trilogy Design",
    value: "Trilogy Design",
  },
  {
    label: "Trina Turk",
    value: "Trina Turk",
  },
  {
    label: "Trish Scully",
    value: "Trish Scully",
  },
  {
    label: "Trotters London",
    value: "Trotters London",
  },
  {
    label: "Trotters Shoes",
    value: "Trotters Shoes",
  },
  {
    label: "True Religion",
    value: "True Religion",
  },
  {
    label: "Truly Me",
    value: "Truly Me",
  },
  {
    label: "Trumpette",
    value: "Trumpette",
  },
  {
    label: "Tsukihoshi",
    value: "Tsukihoshi",
  },
  {
    label: "Tu",
    value: "Tu",
  },
  {
    label: "Tuchinda",
    value: "Tuchinda",
  },
  {
    label: "Tucker + Tate",
    value: "Tucker + Tate",
  },
  {
    label: "TuffRider",
    value: "TuffRider",
  },
  {
    label: "Tula",
    value: "Tula",
  },
  {
    label: "Tumalo Apparel",
    value: "Tumalo Apparel",
  },
  {
    label: "Tumblewalla",
    value: "Tumblewalla",
  },
  {
    label: "Turtledove London",
    value: "Turtledove London",
  },
  {
    label: "Tutto Piccolo",
    value: "Tutto Piccolo",
  },
  {
    label: "Tutu Du Monde",
    value: "Tutu Du Monde",
  },
  {
    label: "Twinkie Tush",
    value: "Twinkie Tush",
  },
  {
    label: "Twirls & Twigs",
    value: "Twirls & Twigs",
  },
  {
    label: "Ty",
    value: "Ty",
  },
  {
    label: "Ub2 / Urban Baby Bonnets",
    value: "Ub2 / Urban Baby Bonnets",
  },
  {
    label: "UGG",
    value: "UGG",
  },
  {
    label: "Ultra Violet Kids",
    value: "Ultra Violet Kids",
  },
  {
    label: "Umbro",
    value: "Umbro",
  },
  {
    label: "Umi",
    value: "Umi",
  },
  {
    label: "Under Armour",
    value: "Under Armour",
  },
  {
    label: "Under the Nile",
    value: "Under the Nile",
  },
  {
    label: "Uniqlo",
    value: "Uniqlo",
  },
  {
    label: "United By Blue",
    value: "United By Blue",
  },
  {
    label: "United Nude",
    value: "United Nude",
  },
  {
    label: "Universal Thread",
    value: "Universal Thread",
  },
  {
    label: "UNTUCKit",
    value: "UNTUCKit",
  },
  {
    label: "UPPAbaby",
    value: "UPPAbaby",
  },
  {
    label: "Upper Looper",
    value: "Upper Looper",
  },
  {
    label: "Urban Outfitters",
    value: "Urban Outfitters",
  },
  {
    label: "Urban Pines Co",
    value: "Urban Pines Co",
  },
  {
    label: "Urban Smalls",
    value: "Urban Smalls",
  },
  {
    label: "Usborne Books",
    value: "Usborne Books",
  },
  {
    label: "U.S. Polo Assn.",
    value: "U.S. Polo Assn.",
  },
  {
    label: "UV Skinz",
    value: "UV Skinz",
  },
  {
    label: "Vans",
    value: "Vans",
  },
  {
    label: "Veja",
    value: "Veja",
  },
  {
    label: "Velveteen",
    value: "Velveteen",
  },
  {
    label: "Velvet Fawn",
    value: "Velvet Fawn",
  },
  {
    label: "Vera Bradley",
    value: "Vera Bradley",
  },
  {
    label: "Vici",
    value: "Vici",
  },
  {
    label: "ViconX",
    value: "ViconX",
  },
  {
    label: "Victoria Beckham",
    value: "Victoria Beckham",
  },
  {
    label: "Victoria's Secret",
    value: "Victoria's Secret",
  },
  {
    label: "Vierra Rose",
    value: "Vierra Rose",
  },
  {
    label: "Vignette",
    value: "Vignette",
  },
  {
    label: "Vigoss",
    value: "Vigoss",
  },
  {
    label: "Vilebrequin",
    value: "Vilebrequin",
  },
  {
    label: "Villervalla",
    value: "Villervalla",
  },
  {
    label: "Vince.",
    value: "Vince.",
  },
  {
    label: "Vince Camuto",
    value: "Vince Camuto",
  },
  {
    label: "Vincent",
    value: "Vincent",
  },
  {
    label: "Vineyard Vines",
    value: "Vineyard Vines",
  },
  {
    label: "Vintage",
    value: "Vintage",
  },
  {
    label: "Vintage Havana",
    value: "Vintage Havana",
  },
  {
    label: "Vintage Lucy's",
    value: "Vintage Lucy's",
  },
  {
    label: "Violet + Moss",
    value: "Violet + Moss",
  },
  {
    label: "Vionic",
    value: "Vionic",
  },
  {
    label: "Vitamin A",
    value: "Vitamin A",
  },
  {
    label: "Viverano Organics",
    value: "Viverano Organics",
  },
  {
    label: "Vivie and Ash",
    value: "Vivie and Ash",
  },
  {
    label: "Vlado",
    value: "Vlado",
  },
  {
    label: "Volcom",
    value: "Volcom",
  },
  {
    label: "Vtech",
    value: "Vtech",
  },
  {
    label: "Vulli",
    value: "Vulli",
  },
  {
    label: "Vuori",
    value: "Vuori",
  },
  {
    label: "Walkiddy",
    value: "Walkiddy",
  },
  {
    label: "Wander & Wonder",
    value: "Wander & Wonder",
  },
  {
    label: "Warner Bros",
    value: "Warner Bros",
  },
  {
    label: "Wauw Capow",
    value: "Wauw Capow",
  },
  {
    label: "Wear Moi",
    value: "Wear Moi",
  },
  {
    label: "Wee Gallery",
    value: "Wee Gallery",
  },
  {
    label: "Wee Monster",
    value: "Wee Monster",
  },
  {
    label: "Wee Rascals",
    value: "Wee Rascals",
  },
  {
    label: "Well Dressed Wolf",
    value: "Well Dressed Wolf",
  },
  {
    label: "Well Worn",
    value: "Well Worn",
  },
  {
    label: "Wendy Bellissimo",
    value: "Wendy Bellissimo",
  },
  {
    label: "Western Chief",
    value: "Western Chief",
  },
  {
    label: "Wes & Willy",
    value: "Wes & Willy",
  },
  {
    label: "Wheat",
    value: "Wheat",
  },
  {
    label: "Whim and Wander",
    value: "Whim and Wander",
  },
  {
    label: "Whistle & Flute",
    value: "Whistle & Flute",
  },
  {
    label: "White House Black Market",
    value: "White House Black Market",
  },
  {
    label: "Widgeon",
    value: "Widgeon",
  },
  {
    label: "WildBird",
    value: "WildBird",
  },
  {
    label: "Wild Fable",
    value: "Wild Fable",
  },
  {
    label: "Wildflowers",
    value: "Wildflowers",
  },
  {
    label: "Wildfox",
    value: "Wildfox",
  },
  {
    label: "Wildling",
    value: "Wildling",
  },
  {
    label: "Wild Rich Kids",
    value: "Wild Rich Kids",
  },
  {
    label: "Wild Wawa",
    value: "Wild Wawa",
  },
  {
    label: "Wilt",
    value: "Wilt",
  },
  {
    label: "Winter Water Factory",
    value: "Winter Water Factory",
  },
  {
    label: "Wire & Honey",
    value: "Wire & Honey",
  },
  {
    label: "Wit & Wisdom",
    value: "Wit & Wisdom",
  },
  {
    label: "Wolf and Rita",
    value: "Wolf and Rita",
  },
  {
    label: "Wolfechild",
    value: "Wolfechild",
  },
  {
    label: "Wonderfold",
    value: "Wonderfold",
  },
  {
    label: "Wonder Nation",
    value: "Wonder Nation",
  },
  {
    label: "Wondershop",
    value: "Wondershop",
  },
  {
    label: "Woodmouse and Thistle",
    value: "Woodmouse and Thistle",
  },
  {
    label: "Woolino",
    value: "Woolino",
  },
  {
    label: "Woombie",
    value: "Woombie",
  },
  {
    label: "Wovenplay",
    value: "Wovenplay",
  },
  {
    label: "WowWee",
    value: "WowWee",
  },
  {
    label: "Wrangler",
    value: "Wrangler",
  },
  {
    label: "Wraredoll",
    value: "Wraredoll",
  },
  {
    label: "Wren and James",
    value: "Wren and James",
  },
  {
    label: "Wunderkin Co.",
    value: "Wunderkin Co.",
  },
  {
    label: "Xaria&Co",
    value: "Xaria&Co",
  },
  {
    label: "XCVI",
    value: "XCVI",
  },
  {
    label: "Xero Shoes",
    value: "Xero Shoes",
  },
  {
    label: "Yellow Box",
    value: "Yellow Box",
  },
  {
    label: "Yellow Pelota",
    value: "Yellow Pelota",
  },
  {
    label: "Yo Baby",
    value: "Yo Baby",
  },
  {
    label: "Yogababy",
    value: "Yogababy",
  },
  {
    label: "Yogalicious",
    value: "Yogalicious",
  },
  {
    label: "Yokono",
    value: "Yokono",
  },
  {
    label: "Yoli & Otis",
    value: "Yoli & Otis",
  },
  {
    label: "Yosi Samra",
    value: "Yosi Samra",
  },
  {
    label: "Young Hearts",
    value: "Young Hearts",
  },
  {
    label: "Youngland",
    value: "Youngland",
  },
  {
    label: "Young Soles",
    value: "Young Soles",
  },
  {
    label: "Zara",
    value: "Zara",
  },
  {
    label: "Zaxy",
    value: "Zaxy",
  },
  {
    label: "Zaza Couture",
    value: "Zaza Couture",
  },
  {
    label: "ZeebraKids",
    value: "ZeebraKids",
  },
  {
    label: "Zella",
    value: "Zella",
  },
  {
    label: "Zipadee-Zip",
    value: "Zipadee-Zip",
  },
  {
    label: "Zippity Do Da",
    value: "Zippity Do Da",
  },
  {
    label: "Zoocchini",
    value: "Zoocchini",
  },
  {
    label: "Zuccini Kids",
    value: "Zuccini Kids",
  },
  {
    label: "Zulu & Zephyr",
    value: "Zulu & Zephyr",
  },
  {
    label: "Zunie",
    value: "Zunie",
  },
  {
    label: "Zutano",
    value: "Zutano",
  },
  {
    label: "Zuzii",
    value: "Zuzii",
  },
  {
    label: "Zyia Active",
    value: "Zyia Active",
  },
];
